interface IPassConfigObject {
  expirationStartDate?: string;
  passwordExpires?: boolean;
  expirationTime?: number;
  firstExpirationTime?: number;
  warningTime?: number;
}

type NewPassValidationValue = IPassConfigObject | string | undefined;
export interface IPassValidationConfig {
  passwordExpires: boolean;
  expirationStartDate?: Date;
  expirationTime?: number;
  firstExpirationTime?: number;
  warningTime?: number;
}

const parsePassValidationConfig = (config: string): NewPassValidationValue =>
  JSON.parse(config) as NewPassValidationValue;

export const parseNewPassValidationConfig = (
  value: NewPassValidationValue
): IPassValidationConfig | undefined => {
  switch (typeof value) {
    case "object": {
      if (!value.expirationStartDate) {
        return undefined;
      }
      const { expirationStartDate, passwordExpires, ...rest } = value;
      const result: IPassValidationConfig = {
        ...rest,
        passwordExpires: passwordExpires === true,
        expirationStartDate: new Date(expirationStartDate),
      };
      if (expirationStartDate !== undefined && expirationStartDate.length > 0) {
        result.passwordExpires = true;
        result.expirationStartDate = new Date(expirationStartDate);
      }
      return result;
    }
    case "string":
      return {
        expirationStartDate: new Date(value),
        passwordExpires: true,
      };
    default:
      return undefined;
  }
};

export interface INewPassValidationConfig {
  [index: string]: IPassValidationConfig | undefined;
}

export const getNewPassValidationConfig = (
  config?: string
): IPassValidationConfig | undefined => {
  const parsedConfig = parsePassValidationConfig(config || "{}");
  return parseNewPassValidationConfig(parsedConfig);
};

export const getStartDate = (config?: string): Date | undefined => {
  const parsedConfig = getNewPassValidationConfig(config);
  return parsedConfig?.expirationStartDate;
};
