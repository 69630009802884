/* eslint-disable spellcheck/spell-checker */
export const handleContactLink = (country: string): string => {
  switch (country.toLocaleLowerCase()) {
    case "mx":
      return "https://update.registronatura.net/";
    case "co":
      return `https://minegocio.be/actualizar`;
    case "cl":
    default:
      return `https://plataformasnatura.com/portal_natura_&_co_ayuda`;
  }
};

export const contactLinkCountries = ["cl", "co", "mx"];
