import { I18n } from "src/I18n/I18n";
import styles from "../styles/styles";
import React from "react";
import { CookiesService } from "src/utils/cookie-service";
import { Country } from "src/interfaces/country";
import {
  ProviderType,
  getFederationUrl,
  getProviderName,
  providers,
} from "src/utils/federation-utils";
import { redirect } from "src/utils/redirect";

interface IButtonProps {
  clientId: string;
  width?: string;
  height?: string;
  i18n: I18n;
  provider: ProviderType;
  cookies: CookiesService;
  country: Country;
}

export class SocialButton extends React.Component<IButtonProps> {
  public constructor(props: IButtonProps) {
    super(props);
  }

  public render(): React.ReactNode {
    const {
      clientId,
      width = "48px",
      height = "48px",
      provider,
      country,
    } = this.props;
    const imageAlt = `${this.props.i18n.get("Enter with")} ${
      providers[provider].alt
    }`;
    const id = `${provider}SignUpButton`;
    return (
      <div style={{ width, height }}>
        <button
          id={id}
          onClick={() => {
            const cookies = this.props.cookies.getCookie("cookieParams");
            this.props.cookies.createCookie("cookieParams", {
              ...cookies,
              provider,
            });
            const providerName = getProviderName(provider, country);
            const federationUrl = getFederationUrl(
              country,
              providerName,
              clientId
            );
            redirect(federationUrl);
          }}
          style={styles.socialButton}
        >
          <img
            src={providers[provider].image}
            alt={imageAlt}
            height={height}
            width={width}
          />
        </button>
      </div>
    );
  }
}
