/* eslint-disable spellcheck/spell-checker */
import { SelectOptions } from "@naturacosmeticos/natds-web/dist/Components/Select/Select.props";
import { Country } from "src/interfaces/country";

export interface IConsultantData {
  country: string;
  data: IConsultantDataObject[];
}

export interface IConsultantDataObject {
  key: string;
  value: string;
}

const personCodeEs = {
  key: "personCode",
  value: "Código Consultora",
};

const emailEs = {
  key: "email",
  value: "Correo electrónico",
};

const consultantDocumentsData = [
  {
    country: "pe",
    data: [
      {
        key: "carnetExtranjeria",
        value: "Carnet de Extranjeria",
      },
      emailEs,
      personCodeEs,
      {
        key: "dni",
        value: "DNI",
      },
      {
        key: "passport",
        value: "Pasaporte",
      },
    ],
  },
  {
    country: "co",
    data: [
      emailEs,
      personCodeEs,
      {
        key: "cedulaCiudadania",
        value: "Cédula de Ciudadanía (CC)",
      },
      {
        key: "cedulaExtranjeria",
        value: "Cédula de Extranjería (CE)",
      },
    ],
  },
  {
    country: "cl",
    data: [
      {
        key: "rut",
        value: "RUT",
      },
      emailEs,
      personCodeEs,
    ],
  },
] as unknown as IConsultantData[];

const consultantDocumentsDataDic = (() => {
  const data: { [id: string]: IConsultantData } = {};
  consultantDocumentsData.forEach((doc) => {
    data[doc.country] = doc;
  });
  return data;
})();

export const getConsultantDocumentsData = (
  country: string
): IConsultantData | undefined => {
  if (country === undefined || country === "") {
    return undefined;
  }
  const countryLowerCase = country.toLowerCase();
  return consultantDocumentsDataDic[countryLowerCase];
};

export const getConsultantDocumentsSelectList = (
  consultantDocumentsData: IConsultantData
): SelectOptions => {
  return consultantDocumentsData.data.map((data) => ({
    description: data.value,
    value: data.key,
  }));
};

export const isConsultantDocuments = (country: string): boolean => {
  const countryLowerCase = country.toLowerCase();
  const countryData = consultantDocumentsData.find(
    (data) => data.country === countryLowerCase
  );
  return countryData !== undefined;
};

export const getDefaultSelectValue = (country: Country): string => {
  return getConsultantDocumentsData(country)?.data[0].key as string;
};
