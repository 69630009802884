// eslint-disable-next-line spellcheck/spell-checker
/* eslint-disable sonarjs/no-duplicate-string */
import React, { ChangeEvent } from "react";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { Input } from "src/components";
import { Button } from "src/components/button";
import { Container } from "src/components/container";
import styles from "src/styles/styles";
import { isNotEmpty } from "src/utils/is-not-empty";
import { decideQuerySeparator } from "src/utils/query-builder";
import { redirect } from "src/utils/redirect";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { ApiError } from "src/interfaces/api-error";
import { PasswordInput } from "src/components/password-input";
import { environment } from "src/config/environment";
import { showCountryFlag } from "src/utils/elo-utils";
import { PasswordInfo } from "src/components/passwordInfo";
import { getStartDate } from "src/config/parse-password-config";
import { FIELD_COULD_NOT_BE_EMPTY } from "src/I18n/I18n-templates";
import crypto from "crypto-js";

export interface IFistAccessPieceState extends IAuthPieceState {
  session: string;
  username: string;
  newPassword: string;
  confirmNewPassword: string;
  newPasswordState?: string;
  newPasswordHelpText?: string;
  confirmNewPasswordState?: string;
  confirmNewPasswordHelpText?: string;
  formHelperErrorMessage?: string;
  formHelperState?: string;
}

export class FirstAccess extends AuthPiece<
  IAuthPieceProps,
  IFistAccessPieceState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IAuthPieceProps) {
    super(props);
    this.getNewPasswordFromInput = this.getNewPasswordFromInput.bind(this);
    this.getConfirmPasswordFromInput =
      this.getConfirmPasswordFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleNewPasswordOnChange = this.handleNewPasswordOnChange.bind(this);
    this.handleConfirmNewPasswordOnChange =
      this.handleConfirmNewPasswordOnChange.bind(this);
    this.getPasswordInputs = this.getPasswordInputs.bind(this);

    this.refButton = React.createRef();
  }

  public getNewPasswordFromInput(): string | undefined {
    return this.state.newPassword;
  }

  public getConfirmPasswordFromInput(): string | undefined {
    return this.state.confirmNewPassword;
  }

  private getPasswordInputs(): React.JSX.Element {
    const startDate = getStartDate(environment.newValidationConfig);
    if (startDate && new Date(startDate).getTime() <= Date.now()) {
      return (
        <>
          <PasswordInput
            className="row"
            style={styles.centerRow}
            i18n={this.props.i18n}
            placeholder="New password"
            helpText={this.state?.newPasswordHelpText}
            value={this.state?.newPassword}
            state={this.state?.newPasswordState}
            onKeyDown={this.handleOnKeyDown}
            onChange={this.handleNewPasswordOnChange}
          />
          <div className="row" style={styles.centerRow}>
            <div className="natds-input-container passwordInput">
              <Input
                id="confirmNewPassword"
                type="password"
                placeholder={this.props.i18n.get("Confirm password")}
                onChange={this.handleConfirmNewPasswordOnChange}
                helpText={this.state?.confirmNewPasswordHelpText}
                state={this.state?.confirmNewPasswordState}
                onKeyDown={this.handleOnKeyDown}
              />
            </div>
          </div>
        </>
      );
    }
    return (
      <>
        <div className="row" style={styles.centerRow}>
          <div
            className="natds-input-container-with-button passwordInput"
            style={{ width: "300px", margin: "10px" }}
          >
            <Input
              id="newPassword"
              type="password"
              placeholder={this.props.i18n.get("New password")}
              onChange={this.handleNewPasswordOnChange}
              helpText={this.state?.newPasswordHelpText}
              state={this.state?.newPasswordState}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div
            className="natds-input-container-with-button passwordInput"
            style={{ width: "300px", margin: "10px" }}
          >
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder={this.props.i18n.get("Confirm password")}
              onChange={this.handleConfirmNewPasswordOnChange}
              helpText={this.state?.confirmNewPasswordHelpText}
              state={this.state?.confirmNewPasswordState}
              onKeyDown={this.handleOnKeyDown}
            />
          </div>
        </div>
        <PasswordInfo i18n={this.props.i18n} />
      </>
    );
  }

  public render(): React.JSX.Element {
    return (
      <Container
        country={showCountryFlag(this.state?.country)}
        company={this.state?.company}
      >
        <div
          className="row"
          style={{ ...styles.centerRow, textAlign: "center" }}
        >
          <div className="natds-input-container">
            <Typography variant="caption" style={{ fontSize: "16px" }}>
              {this.props.i18n.get(
                `Fill in the fields below to create a new password`
              )}
            </Typography>
          </div>
        </div>

        {this.getPasswordInputs()}
        {this.showError()}
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Button
              id="firstAccessButton"
              onClick={async (): Promise<void> => {
                await this.onButtonClick();
              }}
              text={this.props.i18n.get("create new password")}
              itemRef={this.refButton}
              disabled={
                !this.state?.newPassword ||
                this.state?.newPasswordState === "error" ||
                !this.state?.confirmNewPassword ||
                this.state?.confirmNewPasswordState === "error"
              }
            />
          </div>
        </div>
      </Container>
    );
  }

  private handleNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>,
    newPasswordState = "",
    newPasswordHelpText = ""
  ): void {
    const newPassword = event.target.value;

    this.setState({
      newPassword,
      newPasswordHelpText,
      newPasswordState,
    });
    this.validateConfirmNewPassword(
      newPassword,
      this.state.confirmNewPassword ?? ""
    );
  }

  private handleConfirmNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const confirmNewPassword = event.target.value;

    this.setState({
      confirmNewPassword,
    });
    this.validateConfirmNewPassword(
      this.state.newPassword ?? "",
      confirmNewPassword
    );
  }

  private validateConfirmNewPassword(
    newPassword: string,
    confirmNewPassword: string
  ): void {
    if (confirmNewPassword) {
      const confirmNewPasswordState =
        confirmNewPassword === newPassword ? "success" : "error";
      const confirmNewPasswordHelpText =
        confirmNewPasswordState === "error"
          ? this.props.i18n.get("Passwords do not match")
          : "";

      this.setState({
        confirmNewPasswordState,
        confirmNewPasswordHelpText,
      });
    } else {
      this.setConfirmNewPasswordError();
    }
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.setState({ formHelperErrorMessage: "", formHelperState: "" });
      if (!this.validateInput()) {
        return;
      }
      const encryptedPass = crypto.AES.encrypt(
        this.state?.newPassword?.replace(/ /g, ""),
        this.state?.username?.replace(/ /g, "")
      ).toString();

      const result = await this.props.api.resolveFirstAccessChallenge({
        clientId: this.state?.clientId as string,
        country: this.state?.country as string,
        company: this.state?.company as string,
        session: this.state?.session as string,
        username: this.state?.username?.replace(/ /g, "") as string,
        newPassword: encryptedPass,
        redirectUrl: this.state.redirectUri,
      });
      await this.saveUserSession(result.sso_token);

      if (this.state?.country?.toLowerCase() === "my") {
        console.log("Redirecting to application:", result.sso_token);
        this.goBackToApplication(result.sso_token);
        console.log("Redirected success.");
        this.navigate("");
      } else {
        console.log("Setting previous page:", "first-access");
        this.setState({
          previousPage: "first-access",
        });
        console.log("Navigating to redirect:", this.state);
        this.navigate("redirect", this.state);
      }
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.status === 400) {
        console.error("API Error:", apiError.message);
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(apiError.message),
          formHelperState: "error",
        });
      } else {
        console.error("Failed to set password console:", error);
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "Failed to set new password"
          ),
          formHelperState: "error",
        });
      }
    }
  }

  private validateInput(): boolean {
    let isFormValid = true;
    if (!isNotEmpty(this.state?.newPassword)) {
      this.setNewPasswordError();
      isFormValid = false;
    }
    if (!isNotEmpty(this.state?.confirmNewPassword)) {
      this.setConfirmNewPasswordError();
      isFormValid = false;
    }
    if (this.state?.newPassword !== this.state?.confirmNewPassword) {
      this.setPasswordsMismatchError();
      isFormValid = false;
    }
    if (
      this.state?.newPassword.length > 15 ||
      this.state?.confirmNewPassword.length > 15
    ) {
      this.setPasswordsMaxLengthError();
      isFormValid = false;
    }
    return isFormValid;
  }

  private setNewPasswordError() {
    this.setState({
      newPasswordState: "error",
      newPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("New password")
      ),
    });
  }

  private setConfirmNewPasswordError() {
    this.setState({
      confirmNewPasswordState: "error",
      confirmNewPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Confirm new password")
      ),
    });
  }

  private setPasswordsMismatchError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("Passwords do not match"),
      formHelperState: "error",
    });
  }

  private setPasswordsMaxLengthError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "The password should have the max length of 15 characters"
      ),
      formHelperState: "error",
    });
  }

  private goBackToApplication(ssoToken: string): void {
    redirect(
      `${this.state.redirectUri}${decideQuerySeparator(
        this.state.redirectUri
      )}sso_token=${ssoToken}`
    );
  }

  private async saveUserSession(ssoToken: string): Promise<void> {
    const cookieName = this.getCookieName();
    this.props.cookies.createCookie(cookieName, ssoToken);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`;
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      this.refButton.current?.click();
    }
  }

  private showError(): React.ReactNode {
    const formHelperState = this.state?.formHelperState;
    const isShowError =
      formHelperState === undefined || formHelperState === "" ? false : true;
    const errorMessage =
      this.state?.formHelperErrorMessage === undefined
        ? ""
        : this.state?.formHelperErrorMessage;
    const errorMessageScreen = (
      <div className="row" style={styles.centerRow}>
        <div className="natds-input-container" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.helperText}
          >
            {this.props.i18n.get(errorMessage)}
          </FormHelperText>
        </div>
      </div>
    );
    return isShowError ? errorMessageScreen : <></>;
  }
}
