import React, {
  ChangeEvent,
  KeyboardEvent,
  CSSProperties,
  useState,
} from "react";
import { Icon } from "@naturacosmeticos/natds-web";
import { Input } from "src/components";
import { I18n } from "src/I18n/I18n";
import {
  getInvalidPasswordValidationData,
  passwordValidator,
} from "src/utils/password-validator";
import { environment } from "src/config/environment";
import { Country } from "src/interfaces/country";

interface IPasswordInputProps {
  onChange(
    event: ChangeEvent<HTMLInputElement>, // eslint-disable-line no-unused-vars
    state?: string, // eslint-disable-line no-unused-vars
    helpText?: string // eslint-disable-line no-unused-vars
  ): void;
  // eslint-disable-next-line no-unused-vars
  onKeyDown(event: KeyboardEvent<HTMLInputElement>): void;
  i18n: I18n;
  placeholder: string;
  helpText?: string;
  state?: string;
  value?: string;
  style?: CSSProperties;
  className?: string;
  country?: Country;
}

export const PasswordInput = ({
  i18n,
  onChange,
  onKeyDown,
  placeholder,
  helpText,
  state,
  style,
  className,
  country,
}: IPasswordInputProps) => {
  const forbiddenWords = environment.forbiddenWords;
  const minPasswordLength = environment.minPasswordLength;
  const maxPasswordLength = environment.maxPasswordLength;
  const [validation, setValidation] = useState(
    getInvalidPasswordValidationData()
  );
  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value;
    const validation = passwordValidator(
      newValue ?? "",
      forbiddenWords,
      minPasswordLength,
      maxPasswordLength
    );
    const validationState = validation.isValid ? "success" : "error";
    const validationHelpText = validation.isValid
      ? ""
      : "The password does not meet all requirements";

    onChange(event, validationState, validationHelpText);
    setValidation(validation);
  };

  const forbiddenWordsText =
    country === "mx"
      ? `${country}:With forbidden words`
      : "Without forbidden words";

  return (
    <div className={className} style={style}>
      <div className="natds-input-container passwordInput">
        <Input
          id="newPassword"
          type="password"
          placeholder={i18n.get(placeholder)}
          onChange={handleChange}
          helpText={i18n.get(helpText ?? "")}
          state={state}
          onKeyDown={onKeyDown}
        />
        <div
          style={{
            marginTop: "0.5em",
            display: "flex",
            justifyContent: "start",
            width: "100%",
            flexWrap: "wrap",
          }}
        >
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasNumber}
            validationMessage="Minimum one number"
            exampleMessage="0, 1, 2, 3"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasLowerCase}
            validationMessage="Minimum one lowercase letter"
            // eslint-disable-next-line spellcheck/spell-checker
            exampleMessage="abc"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasUpperCase}
            validationMessage="Minimum one uppercase letter"
            exampleMessage="ABC"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasSpecialCharacter}
            validationMessage="Minimum one special character"
            exampleMessage="Ex.: @, %, $"
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.isLong}
            validationMessage="Minimum ### characters"
            validationMessageParameter={String(minPasswordLength)}
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.isTooLong}
            validationMessage="Maximum ### characters"
            validationMessageParameter={String(maxPasswordLength)}
          />
          <PasswordMessage
            i18n={i18n}
            isValid={validation.hasNoForbiddenWords}
            validationMessage={forbiddenWordsText}
            exampleMessage={forbiddenWords.join(", ")}
          />
        </div>
      </div>
    </div>
  );
};

interface IPasswordMessageProps {
  i18n: I18n;
  isValid: boolean;
  validationMessage: string;
  validationMessageParameter?: string;
  exampleMessage?: string;
}

const PasswordMessage = ({
  i18n,
  isValid,
  validationMessage,
  validationMessageParameter,
  exampleMessage,
}: IPasswordMessageProps) => {
  const validationMessageTranslated = i18n.getByTemplate(
    validationMessage,
    validationMessageParameter
  );
  return (
    <div
      style={{
        marginTop: "0.1em",
        display: "flex",
        alignItems: "center",
        width: "100%",
        color: isValid ? "green" : "gray",
      }}
    >
      <div style={{ padding: "0.1em" }}>
        <Icon name="outlined-action-check" size="tiny" />
      </div>
      <div style={{ textAlign: "left", fontSize: 12 }}>
        {validationMessageTranslated}
        {exampleMessage ? ` (${exampleMessage})` : ""}
      </div>
    </div>
  );
};
