import { environment } from "src/config/environment";

/* eslint-disable spellcheck/spell-checker */
export const enableDynatrace = () => {
  if (
    environment.dynatracePath === undefined ||
    environment.dynatracePath === ""
  ) {
    return;
  }
  const script = document.createElement("script");
  script.type = "text/javascript";
  script.src = `https://js-cdn.dynatrace.com/jstag/${environment.dynatracePath}_complete.js`;
  script.crossOrigin = "anonymous";
  script.async = true;

  document.head.appendChild(script);
};
/* eslint-enable spellcheck/spell-checker */
