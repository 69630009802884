/* eslint-disable spellcheck/spell-checker */
import React from "react";
import styles from "../styles/styles";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Image, Typography } from "@naturacosmeticos/natds-web";
import { AppContext } from "src/utils/context";
import { Container } from "src/components/container";
import { showCountryFlag } from "src/utils/elo-utils";
import { environment } from "src/config/environment";
import parse from "html-react-parser";

export class Maintenance extends AuthPiece<IAuthPieceProps, IAuthPieceState> {
  static contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;

  public constructor(props: IAuthPieceProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
  }

  public render(): React.JSX.Element {
    return (
      <Container
        company={this.state?.company}
        country={showCountryFlag(this.state?.country, true)}
      >
        {environment.maintenanceImage && (
          <div className="row" style={styles.centerRow}>
            <div style={{ margin: "20px" }}>
              <Image
                src={environment.maintenanceImage}
                alt={environment.maintenanceImageAlt}
                style={styles.maintenanceImage}
              />
            </div>
          </div>
        )}

        {environment.maintenanceText && (
          <div className="row" style={styles.centerRow}>
            <div style={{ margin: "20px", width: "300px" }}>
              <Typography color="textPrimary">
                {parse(this.props.i18n.get(environment.maintenanceText))}
              </Typography>
            </div>
          </div>
        )}
      </Container>
    );
  }
}
