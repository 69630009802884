import { parseMaintenanceMode } from "src/utils/maintenance-service-date";
import { Environment } from "../interfaces/environment";
import { parseCountryList, parseSMSConfig } from "./parse-utils";

export const environment = {
  apiDomain: process.env.REACT_APP_API_DOMAIN as string,
  apiDomainBR: process.env.REACT_APP_API_DOMAIN_BR as string,
  apiPrePrdDomain: process.env.REACT_APP_API_PRE_PRD_DOMAIN,
  apiPrePrdDomainBR: process.env.REACT_APP_API_PRE_PRD_DOMAIN_BR,
  prePrdHostname: process.env.REACT_APP_PRE_PRD_HOSTNAME,
  apiToken: process.env.REACT_APP_API_TOKEN as string,
  environment: process.env.REACT_APP_ENVIRONMENT as Environment,
  geraSendPassUrl: process.env.REACT_APP_GERA_SEND_PASS_URL as string,
  secAccessPassUrl: process.env.REACT_APP_SEC_ACCESS_SEND_PASS_URL as string,
  eloEnabledCountries: parseCountryList(
    process.env.REACT_APP_ELO_ENABLED_COUNTRIES
  ),
  cognitoLatamDomain: process.env.REACT_APP_COGNITO_LATAM_DOMAIN as string,
  cognitoBrDomain: process.env.REACT_APP_COGNITO_BR_DOMAIN as string,
  smsConfig: parseSMSConfig(process.env.REACT_APP_SMS_CONFIG),
  newValidationConfig: process.env.REACT_APP_PASS_VALIDATION_CONFIG as string,
  newPassValidationWhiteList: JSON.parse(
    process.env.REACT_APP_NEW_PASS_VALIDATION_WHITE_LIST || "[]"
  ) as string[],
  forbiddenWords: JSON.parse(
    process.env.REACT_APP_FORBIDDEN_WORDS || "[]"
  ) as string[],
  minPasswordLength: Number(
    process.env.REACT_APP_MIN_PASSWORD_LENGTH as string
  ),
  maxPasswordLength: Number(
    process.env.REACT_APP_MAX_PASSWORD_LENGTH as string
  ),
  socialLoginEnabled: process.env.REACT_APP_SOCIAL_LOGIN_ENABLED || "{}",
  dynatracePath: process.env.REACT_APP_DYNATRACE_PATH,
  loginByDocumentCountries: parseCountryList(
    process.env.REACT_APP_LOGIN_BY_DOCUMENT_COUNTRIES
  ),
  signUpUrl: process.env.REACT_APP_CADASTRO_URL as string,
  signUpUrlMX: process.env.REACT_APP_CADASTRO_URL_MX as string,
  otpEnabledCountries: parseCountryList(process.env.REACT_APP_OTP_COUNTRIES),
  otpSMSDisabledCountries: parseCountryList(
    process.env.REACT_APP_OTP_SMS_OFF_COUNTRIES
  ),
  otpWhatsappDisabledCountries: parseCountryList(
    process.env.REACT_APP_OTP_WHATSAPP_OFF_COUNTRIES
  ),
  maintenanceText: process.env.REACT_APP_MAINTENANCE_TEXT as string,
  maintenanceBypassClientID: process.env.REACT_APP_MAINTENANCE_BYPASS || "",
  maintenanceMode: parseMaintenanceMode(
    process.env.REACT_APP_MAINTENANCE_MODE || "{}"
  ),
  maintenanceImage: process.env.REACT_APP_MAINTENANCE_IMAGE as string,
  maintenanceImageAlt: process.env.REACT_APP_MAINTENANCE_IMAGE_ALT as string,
  deviceOrigin: (process.env.REACT_APP_DEVICE_ORIGIN as string) || "",
  encryptionKey: process.env.REACT_APP_ENCRYPTION_KEY as string,
};
