import React from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Container } from "../components/container";
import { showCountryFlag } from "src/utils/elo-utils";

export type ISignOutProps = IAuthPieceProps;

export interface ISignOutState extends IAuthPieceState {
  sso_token?: string;
}

export class SignOut extends AuthPiece<ISignOutProps, ISignOutState> {
  public constructor(props: ISignOutProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    await this.handleSignOutUser();
  }

  public render(): React.JSX.Element {
    return <Container company={showCountryFlag(this.state?.country)} />;
  }

  private async handleSignOutUser(): Promise<void> {
    const cookieName = this.getCookieName();
    const ssoToken = this.props.cookies.getCookie(cookieName);
    try {
      await this.props.api.signOut(ssoToken);
    } catch (err) {
      console.log("failed to sign-out", err);
    } finally {
      this.eraseUserSession();
      this.goBackToLogin();
    }
  }

  private goBackToLogin(): void {
    this.navigate("");
  }

  private eraseUserSession(): void {
    const cookieName = this.getCookieName();
    this.props.cookies.removeCookie(cookieName);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`;
  }
}
