import Axios, { AxiosError, AxiosRequestConfig } from "axios";
import { SignInResponse } from "./interfaces/sigin-response";
import { SignInBody } from "./interfaces/signin-body";
import { PasswordPattern } from "./interfaces/password-pattern";
import { ApiError } from "../interfaces/api-error";
import { UserSession } from "../interfaces/user-session";
import { ForgotPasswordBody } from "./interfaces/forgot-password-body";
import { ChangePasswordBody } from "./interfaces/change-password-body";
import { ConfirmForgotPasswordBody } from "./interfaces/confirm-forgot-password-body";
import { ResendTemporaryPasswordBody } from "./interfaces/resend-temporary-password";
import { SignOutResponse } from "./interfaces/signout-response";
import { FirstAccessChallengeBody } from "./interfaces/first-access-challenge-body";
import { FirstAccessChallengeResponse } from "./interfaces/first-access-challenge-response";
import { FetchUserInfoParams } from "./interfaces/fetch-user-info-params";
import { FetchUserInfoResponse } from "./interfaces/fetch-user-info-response";
import { ForgotPasswordResponse } from "./interfaces/forgot-password-response";
import { FetchUserContactsResponse } from "./interfaces/fetch-user-contacts-response";
import { FetchPasswordPolicyResponse } from "./interfaces/fetch-password-policy-response";
import { FetchPasswordPolicyParams } from "./interfaces/fetch-password-policy-params";
import { SocialSignInBody } from "./interfaces/social-signin-body";
import { GetUserInfoResponse } from "./interfaces/get-user-info-response";
import { GetUserInfoParams } from "./interfaces/get-user-info-params";
import { OTPChangePasswordParams } from "./interfaces/otp-change-password-params";
import { TokenSendParams } from "./interfaces/token-send-params";
import { TokenValidateParams } from "./interfaces/token-validate-params";
import { TokenValidateResponse } from "./interfaces/token-validate-response";
import { OTPLinkFederationParams } from "./interfaces/otp-link-federation-params";

export class BffApi {
  private readonly domain: string;
  private readonly token: string;
  private readonly cache: Record<string, object>;

  public constructor(domain: string, token: string) {
    this.domain = domain;
    this.token = token;
    this.cache = {};
  }

  public async signIn(body: SignInBody): Promise<SignInResponse> {
    try {
      const request = this.buildRequest("POST", "signin");
      request.data = body;
      const response = await Axios.request<SignInResponse>(request);
      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async socialSignIn(body: SocialSignInBody): Promise<SignInResponse> {
    try {
      const request = this.buildRequest("POST", "social-signin");
      request.data = body;
      const response = await Axios.request<SignInResponse>(request);
      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async signOut(ssoToken: string): Promise<string> {
    try {
      const request = this.buildRequest("POST", `signout`);
      request.data = {
        token: ssoToken,
      };
      const response = await Axios.request<SignOutResponse>(request);
      return response.data.sso_token;
    } catch (err) {
      throw new ApiError(this.catchAxiosError(err as AxiosError));
    }
  }

  public async getPasswordPattern(): Promise<PasswordPattern> {
    try {
      const request = this.buildRequest("GET", "password-pattern");
      const response = await Axios.request<PasswordPattern>(request);
      return response.data;
    } catch (err) {
      throw new ApiError(this.catchAxiosError(err as AxiosError));
    }
  }

  public async forgotPassword(
    body: ForgotPasswordBody
  ): Promise<ForgotPasswordResponse> {
    try {
      const request = this.buildRequest("POST", "forgot-password");
      request.data = body;
      const result = await Axios.request<ForgotPasswordResponse>(request);
      return result.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async changePassword(body: ChangePasswordBody): Promise<void> {
    try {
      const request = this.buildRequest("POST", "change-password");
      request.data = body;
      await Axios.request(request);
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async confirmForgotPassword(
    body: ConfirmForgotPasswordBody
  ): Promise<void> {
    try {
      const request = this.buildRequest("POST", "confirm-forgot-password");
      request.data = body;
      await Axios.request(request);
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async resendTemporaryPassword(
    body: ResendTemporaryPasswordBody
  ): Promise<void> {
    try {
      const request = this.buildRequest("POST", "resend-temporary-password");
      request.data = body;
      await Axios.request(request);
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async recoverSession(
    ssoToken: string,
    redirectUrl: string,
    clientId: string
  ): Promise<Partial<UserSession>> {
    try {
      const request = this.buildRequest("POST", `recover-session`);
      request.data = {
        token: ssoToken,
        redirectUrl,
        clientId,
      };
      const response = await Axios.request<Partial<UserSession>>(request);
      return response.data;
    } catch (err) {
      throw new ApiError(this.catchAxiosError(err as AxiosError));
    }
  }

  public async resolveFirstAccessChallenge(
    body: FirstAccessChallengeBody
  ): Promise<FirstAccessChallengeResponse> {
    try {
      const request = this.buildRequest("POST", "first-access-challenge");
      request.data = body;
      const response = await Axios.request<FirstAccessChallengeResponse>(
        request
      );
      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async fetchUserInfo(
    params: FetchUserInfoParams
  ): Promise<FetchUserInfoResponse> {
    try {
      const request = this.buildRequest("GET", "user-info");
      request.params = params;
      const result = await Axios.request(request);
      return result.data as FetchUserInfoResponse;
    } catch (err) {
      throw new ApiError(this.catchAxiosError(err as AxiosError));
    }
  }

  public async fetchUserContacts(
    params: FetchUserInfoParams
  ): Promise<FetchUserContactsResponse> {
    try {
      const request = this.buildRequest("GET", "user-contacts");
      request.params = params;
      const result = await Axios.request(request);
      return result.data as FetchUserContactsResponse;
    } catch (err) {
      throw new ApiError(this.catchAxiosError(err as AxiosError));
    }
  }

  public async fetchPasswordPolicy(
    params: FetchPasswordPolicyParams
  ): Promise<FetchPasswordPolicyResponse> {
    try {
      const request = this.buildRequest("GET", "password-policy");
      request.params = params;
      const result = await Axios.request<FetchPasswordPolicyResponse>(request);
      return result.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async fetchPasswordPolicyCache(
    params: FetchPasswordPolicyParams
  ): Promise<FetchPasswordPolicyResponse> {
    const key = `passwordPolicy-${params.company}-${params.country}-${params.language}`;
    let result: FetchPasswordPolicyResponse | null = null;
    if (this.cache[key]) {
      result = this.cache[key] as FetchPasswordPolicyResponse;
    } else {
      result = await this.fetchPasswordPolicy(params);
      this.cache[key] = result;
    }
    return result;
  }

  public async getUserInfo(
    params: GetUserInfoParams
  ): Promise<GetUserInfoResponse> {
    try {
      const request = this.buildRequest("GET", "get-user-info");
      request.params = params;
      const result = await Axios.request<GetUserInfoResponse>(request);
      return result.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async otpChangePassword(body: OTPChangePasswordParams): Promise<void> {
    try {
      const request = this.buildRequest("POST", "otp-change-password");
      request.data = body;
      await Axios.request(request);
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async tokenSend(body: TokenSendParams): Promise<void> {
    try {
      const request = this.buildRequest("POST", "token-send");
      request.data = body;
      await Axios.request(request);
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async tokenValidate(
    body: TokenValidateParams
  ): Promise<TokenValidateResponse> {
    try {
      const request = this.buildRequest("POST", "token-validate");
      request.data = body;
      const response = await Axios.request<TokenValidateResponse>(request);
      return response.data;
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  public async otpLinkFederation(body: OTPLinkFederationParams): Promise<void> {
    try {
      const request = this.buildRequest("POST", "otp-link-federation");
      request.data = body;
      await Axios.request(request);
    } catch (err) {
      const axiosError = err as AxiosError;
      throw new ApiError(
        this.catchAxiosError(axiosError),
        axiosError.response?.status
      );
    }
  }

  private catchAxiosError(error: AxiosError): string {
    if (error.response !== undefined && error.response !== null) {
      const data = error.response?.data as ApiError;
      if (data.message !== undefined && data.message != null) {
        return data.message;
      }
      return JSON.stringify(data);
    } else if (error.request !== undefined && error.request !== null) {
      return "request error";
    } else {
      return error.message;
    }
  }

  private buildRequest(
    method: string,
    URI: string,
    params?: object,
    additionalHeaders?: object
  ): AxiosRequestConfig {
    return {
      headers: Object.assign({}, additionalHeaders, {
        "x-api-key": this.token,
      }),
      method,
      params,
      url: `${this.trimChars(this.domain ?? "", "/")}/${URI}`,
    } as AxiosRequestConfig;
  }

  private trimChars(word: string, c: string): string {
    const re = new RegExp(`^[${c}]+|[${c}]+$`, "g");
    return word.replace(re, "");
  }
}
