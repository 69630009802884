import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@naturacosmeticos/natds-web";
import { Button } from "./button";
import { I18n } from "src/I18n/I18n";

export interface IPasswordValidationExpiredModalProps {
  visible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChoose: (resetPassword?: boolean) => void;
  i18n: I18n;
}

export const PasswordValidationExpiredModal = ({
  visible = false,
  onChoose,
  i18n,
}: IPasswordValidationExpiredModalProps): React.JSX.Element => (
  <Dialog
    maxWidth="xs" // eslint-disable-line spellcheck/spell-checker
    open={visible}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>
      <div style={{ display: "flex" }}>{i18n.get("Update your password")}</div>
    </DialogTitle>
    <DialogContent>
      <DialogContentText style={{ width: "100%", textAlign: "left" }}>
        {i18n.get(
          "We have noticed that your current password is not strong enough. For the safety of your account, please update your password."
        )}
      </DialogContentText>
      <Button
        variant="contained"
        style={{ width: "100%" }}
        onClick={async () => onChoose(true)}
        text={i18n.get("Update password")}
      />
    </DialogContent>
  </Dialog>
);
