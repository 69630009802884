import React from "react";
import { I18n } from "src/I18n/I18n";
import { isSocialLoginEnable } from "src/utils/federation-utils";
import styles from "src/styles/styles";
import { SocialButton } from "./federationButtons";
import { Country } from "src/interfaces/country";
import { Typography } from "@naturacosmeticos/natds-web";
import { CookiesService } from "src/utils/cookie-service";

export interface IPasswordUpcomingExpirationModalProps {
  i18n: I18n;
  country: Country;
  clientId: string;
  cookies: CookiesService;
}

export const SocialLoginScreen = ({
  i18n,
  country,
  clientId,
  cookies,
}: IPasswordUpcomingExpirationModalProps): React.JSX.Element => {
  const isEnable = isSocialLoginEnable(country, clientId);
  const socialLoginNaturaTextOnly = ["mx"];
  if (!isEnable) {
    return <></>;
  }
  return (
    <div style={{ marginTop: "20px", marginBottom: "35px" }}>
      <div style={styles.centerRow}>
        <Typography
          variant="caption"
          style={{
            fontSize: "15px",
            maxWidth: "290px",
          }}
        >
          {i18n.get(
            `${
              socialLoginNaturaTextOnly.includes(country) ? "natura:" : ""
            }Enter with`
          )}
        </Typography>
      </div>
      <div
        className="row"
        style={{
          marginTop: "20px",
          alignItems: "center",
          ...styles.centerRow,
        }}
      >
        <div style={{ marginRight: "16px" }}>
          <SocialButton
            clientId={clientId}
            i18n={i18n}
            provider="facebook"
            cookies={cookies}
            country={country}
          />
        </div>
        <div
          style={{ marginRight: showBindIdButton(country) ? "16px" : "0px" }}
        >
          <SocialButton
            clientId={clientId}
            i18n={i18n}
            provider="google"
            cookies={cookies}
            country={country}
          />
        </div>
        {showBindIdButton(country) && (
          <div>
            <SocialButton
              clientId={clientId}
              i18n={i18n}
              provider="passwordless"
              cookies={cookies}
              country={country}
            />
          </div>
        )}
      </div>
    </div>
  );
};

const showBindIdButton = (country: Country): boolean => {
  if (country === "mx" || country === "ec") {
    return false;
  }
  return true;
};
