import { Icon, IconButton } from "@naturacosmeticos/natds-web";
import { useHistory } from "react-router-dom";

export const BackButton = (): React.JSX.Element => {
  const history = useHistory();
  return (
    <IconButton
      aria-label="close"
      onClick={(): void => history.goBack()}
      style={{ position: "fixed", top: "10px", left: "10px" }}
    >
      <Icon
        // eslint-disable-next-line spellcheck/spell-checker
        name="outlined-navigation-goback"
        size="tiny"
      />
    </IconButton>
  );
};
