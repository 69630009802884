import { environment } from "src/config/environment";
import { Country } from "src/interfaces/country";
import styles from "src/styles/styles";
import { getLoginDocumentsData } from "./login-documents-data";
import { getConsultantDocumentsData } from "./consultant-documents-data";

export const validateLoginDocumentsDataByCountry = (
  country: Country
): boolean => {
  return (
    environment.loginByDocumentCountries?.includes(country) &&
    getLoginDocumentsData(country) !== undefined
  );
};

export const validateConsultantDocumentsDataByCountry = (
  country: Country
): boolean => {
  return (
    environment.loginByDocumentCountries?.includes(country) &&
    getConsultantDocumentsData(country) !== undefined
  );
};

export const getLoginInputStyling = (country: Country) => {
  return validateLoginDocumentsDataByCountry(country)
    ? styles.inputContainerDocument
    : styles.inputContainer;
};

export const getLoginButtonStyling = (country: Country) => {
  return validateLoginDocumentsDataByCountry(country)
    ? styles.ButtonContainerDocument
    : styles.ButtonContainer;
};

export const getFirstAccessInputStyling = (country: Country) => {
  return validateConsultantDocumentsDataByCountry(country)
    ? styles.inputContainerFirstAccess
    : styles.inputContainer;
};

export const getFirstAccessButtonStyling = (country: Country) => {
  return validateConsultantDocumentsDataByCountry(country)
    ? styles.ButtonContainerFirstAccess
    : styles.ButtonContainer;
};
export const getSubTitleStyling = (country: Country) => {
  return environment.loginByDocumentCountries?.includes(country)
    ? styles.newSubTitle
    : styles.subTitle;
};

export const getErrorText = (country: Country) => {
  return environment.loginByDocumentCountries?.includes(country)
    ? styles.newHelperText
    : styles.helperText;
};

export const getHelperTextStyling = (country: Country) => {
  return environment.loginByDocumentCountries?.includes(country)
    ? styles.verificationHelperText
    : styles.helperText;
};
