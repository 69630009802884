import { environment } from "src/config/environment";
import { I18n } from "src/I18n/I18n";
import { Country } from "src/interfaces/country";
import { Language } from "src/interfaces/language";

export const eloIsEnabled = (country: Country): boolean =>
  environment.eloEnabledCountries.includes(country);

export const otpIsEnabled = (country: Country): boolean =>
  environment.otpEnabledCountries.includes(country);

export const otpSendSMS = (country: Country): boolean =>
  !environment.otpSMSDisabledCountries.includes(country);

export const otpSendWhatsapp = (country: Country): boolean =>
  !environment.otpWhatsappDisabledCountries.includes(country);

export const setFavicon = (country: Country): void => {
  const favicon = document.getElementById("favicon") as HTMLLinkElement;
  if (favicon === null) {
    return;
  }
  const isEnabled = eloIsEnabled(country);
  const publicUrl = process.env.PUBLIC_URL;
  if (isEnabled) {
    // eslint-disable-next-line spellcheck/spell-checker
    favicon.href = `${publicUrl}/favicon-consultoria-de-beleza.png`;
  } else {
    // eslint-disable-next-line spellcheck/spell-checker
    favicon.href = `${publicUrl}/favicon.ico`;
  }
  const faviconApple = document.getElementById(
    "apple-favicon"
  ) as HTMLLinkElement;
  if (faviconApple === null) {
    return;
  }
  if (isEnabled) {
    // eslint-disable-next-line spellcheck/spell-checker
    faviconApple.href = `${publicUrl}/favicon-consultoria-de-beleza.png`;
  } else {
    // eslint-disable-next-line spellcheck/spell-checker
    faviconApple.href = `${publicUrl}/natura-logo.svg`;
  }
};

export const setTitle = (
  i18n: I18n,
  country: Country,
  language: Language
): void => {
  const key = eloIsEnabled(country)
    ? "Natura and Avon"
    : "Natura Authentication";
  document.title = i18n.getByLanguage(language, key) ?? key;
};

export const showCountryFlag = (
  country?: Country,
  alwaysShowFlag = false
): Country | undefined =>
  country && (alwaysShowFlag || !eloIsEnabled(country)) ? country : undefined;
