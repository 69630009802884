import React from "react";
import {
  Dialog,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  Icon,
} from "@naturacosmeticos/natds-web";
import { Button } from "./button";
import { I18n } from "src/I18n/I18n";

export interface IPasswordValidationUpcomingExpirationModalProps {
  visible?: boolean;
  // eslint-disable-next-line no-unused-vars
  onChoose: (resetPassword?: boolean) => void;
  i18n: I18n;
}

export const PasswordValidationUpcomingExpirationModal = ({
  visible = false,
  onChoose,
  i18n,
}: IPasswordValidationUpcomingExpirationModalProps): React.JSX.Element => (
  <Dialog
    maxWidth="xs" // eslint-disable-line spellcheck/spell-checker
    open={visible}
    onClose={() => onChoose(false)}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>
      <div style={{ display: "flex" }}>
        <div style={{ marginRight: "auto" }}>
          {i18n.get("Strengthen your password")}
        </div>
        <IconButton aria-label="close" onClick={() => onChoose(false)}>
          <Icon name="outlined-navigation-close" size="tiny" />
        </IconButton>
      </div>
    </DialogTitle>
    <DialogContent>
      <DialogContentText style={{ width: "100%", textAlign: "left" }}>
        {i18n.get(
          "We have noticed that your current password is not strong enough. It is highly recommended that you update your password for the safety of your account."
        )}
      </DialogContentText>
      <Button
        variant="contained"
        style={{ width: "100%" }}
        onClick={async () => onChoose(true)}
        text={i18n.get("Update password")}
      />
      <Button
        variant="text"
        style={{ width: "100%", color: "black" }}
        onClick={async () => onChoose(false)}
        text={i18n.get("Continue without changing")}
      />
    </DialogContent>
  </Dialog>
);
