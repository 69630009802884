/* eslint-disable spellcheck/spell-checker */
/* eslint-disable no-unused-vars */
import { environment } from "src/config/environment";

type IDeviceOrigin = Record<string, string>;

export const getDeviceOrigin = (clientId: string): string | null => {
  const deviceOrigin = JSON.parse(environment.deviceOrigin) as IDeviceOrigin;
  return deviceOrigin[clientId];
};
