import { Country } from "src/interfaces/country";

export const countries: Country[] = [
  "ar",
  "br",
  "co",
  "cl",
  "pe",
  "my",
  "mx",
  "ec",
];
