import React, { useEffect, useState } from "react";
import styles from "../styles/styles";
import { Logo as LogoNaturaDS } from "@naturacosmeticos/natds-web";
import {
  getCountry,
  getLanguage,
  getURLSearchParams,
} from "src/utils/query-params-utils";
import { eloIsEnabled } from "src/utils/elo-utils";

export const Logo = (): React.JSX.Element => {
  const [useBModel, setUseBModel] = useState(false);

  useEffect((): void => {
    const urlSearchParams = getURLSearchParams();
    const country = getCountry(urlSearchParams);
    const language = getLanguage(urlSearchParams);
    setUseBModel(eloIsEnabled(country) && language === "pt");
  }, []);

  return (
    <div className="row" style={styles.logoView}>
      <div style={styles.logo}>
        <LogoNaturaDS model={useBModel ? "b" : "a"} />
      </div>
    </div>
  );
};
