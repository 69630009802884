// eslint-disable-next-line spellcheck/spell-checker
/* eslint-disable sonarjs/no-duplicate-string */
import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input, Link } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import {
  FormHelperText,
  Select,
  Typography,
} from "@naturacosmeticos/natds-web";
import { isNotEmpty } from "class-validator";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ForgotPasswordBody } from "src/api/interfaces/forgot-password-body";
import { FetchUserInfoResponse } from "src/api/interfaces/fetch-user-info-response";
import { attemptLimitExceededMessage } from "src/utils/attempt-limit-exceeded-message";
import { isTooManyRequestsError } from "src/utils/too-many-requests-validator";
import { ApiError } from "src/interfaces/api-error";
import { FetchUserContactsResponse } from "src/api/interfaces/fetch-user-contacts-response";
import { UserContactsModal } from "src/components/user-contacts-modal";
import { environment } from "src/config/environment";
import {
  getLoginDefaultSelectValue,
  getLoginDocumentsData,
} from "src/utils/login-documents-data";
import {
  getConsultantDocumentsSelectList,
  getDefaultSelectValue,
} from "src/utils/consultant-documents-data";
import {
  getErrorText,
  getFirstAccessButtonStyling,
  getFirstAccessInputStyling,
  getLoginButtonStyling,
  getLoginInputStyling,
  getSubTitleStyling,
  validateLoginDocumentsDataByCountry,
} from "src/utils/styling-handler";
import { FIELD_COULD_NOT_BE_EMPTY } from "src/I18n/I18n-templates";
import { phoneSVG } from "src/assets/phones/phone";
import { cellPhoneSVG } from "src/assets/phones/cellphone";
import crypto from "crypto-js";

const countriesWithSMSReset = environment.smsConfig.reset?.countries ?? [];

export type IForgotPasswordProps = IAuthPieceProps;

export interface IForgotPasswordState extends IAuthPieceState {
  usernameState?: string;
  usernameHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string;
  showResendTemporaryPasswordLink?: boolean;
  showSendOptions?: boolean;
  consultantDocumentSelectState: "error" | "success" | undefined;
  consultantDocumentHelpText?: string;
}

export class ForgotPassword extends AuthPiece<
  IForgotPasswordProps,
  IForgotPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IForgotPasswordProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.onCloseSendOptions = this.onCloseSendOptions.bind(this);
    this.onSelectSendOptions = this.onSelectSendOptions.bind(this);

    this.refButton = React.createRef();

    this.setState({
      username: "",
      usernameState: "",
      usernameHelpText: "",
      formHelperState: "",
      formHelperErrorMessage: "",
      showResendTemporaryPasswordLink: false,
      showSendOptions: false,
      email: "",
      phoneNumber: "",
    });
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    this.setState({
      showSendOptions: false,
      email: "",
      phoneNumber: "",
      consultantDocument: environment.loginByDocumentCountries.includes(
        this.state?.country
      )
        ? getLoginDefaultSelectValue(this.state?.country)
        : getDefaultSelectValue(this.state?.country),
    });
  }

  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  public getWarningTextPe(): React.JSX.Element {
    return (
      <span>
        {this.props.i18n.get(
          "Enter your consultant code and/or email and receive instructions to recover your password. If you don't have an email, contact the customer service center."
        )}
        <div>
          <img src={phoneSVG} alt="" height="15px" width="16px" />{" "}
          {this.props.i18n.get("0800-80-510 for calls from a landline")}
        </div>
        <div>
          <img src={cellPhoneSVG} alt="" height="15px" width="16px" />{" "}
          {this.props.i18n.get("01700-9300 for calls from cell phone")}
        </div>
      </span>
    );
  }

  public getWarningText(): React.JSX.Element | string {
    const country = this.state?.country;
    const text = this.props.i18n.get(
      "Enter your registered code and receive instructions to recover your password. If you do not have an email, contact customer service"
    );
    if (country === "my") {
      return this.props.i18n.get(
        "Enter your registered e-mail address and receive instructions to recover your password. If you do not have an email, contact customer service"
      );
    } else if (country === "co") {
      return this.props.i18n.get(
        "Enter your Consulting code and receive instructions to recover your password. If you do not have an email, contact our line 018000969010 or 6015188505."
      );
    } else if (country === "mx") {
      return this.props.i18n.get(
        "Enter your registered code and receive instructions to recover your password. If you do not have an email, contact customer service (CS)"
      );
    } else if (country === "pe") {
      return this.getWarningTextPe();
    } else if (countriesWithSMSReset.includes(country)) {
      return this.props.i18n.get(
        "Enter your registered code and receive instructions to recover your password. If you do not have an email or cell phone number, contact customer service"
      );
    }
    return text;
  }

  private onCloseSendOptions(): void {
    this.setState({ showSendOptions: false });
  }

  private onSelectSendOptions(medium: "SMS" | "EMAIL"): void {
    this.setState({ showSendOptions: false });
    const encryptedUsername = crypto.AES.encrypt(
      this.state?.username?.replace(/ /g, "") as string,
      environment.encryptionKey
    ).toString();

    this.requestForgotPassword(encryptedUsername, medium);
  }

  public render(): React.JSX.Element {
    const country = this.state?.country ?? "";
    const useLoginStyle = validateLoginDocumentsDataByCountry(country);
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        <div className="row" style={styles.centerRow}>
          <div style={getSubTitleStyling(country) as React.CSSProperties}>
            <Typography variant="caption" style={{ fontSize: "16px" }}>
              {this.getWarningText()}
            </Typography>
          </div>
        </div>
        {this.state ? (
          <UserContactsModal
            {...this.props}
            title="Verify if this account belongs to you. Choose how to receive the verification code"
            visible={this.state.showSendOptions}
            email={this.state.email}
            phoneNumber={this.state.phoneNumber}
            onCancel={() => this.onCloseSendOptions()}
            onSelect={async (medium) => this.onSelectSendOptions(medium)}
            i18n={this.props.i18n}
          />
        ) : null}

        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginInputStyling(country)
                : getFirstAccessInputStyling(country)
            }
          >
            {useLoginStyle ? this.MenuDocumentsData() : null}
            <Input
              id="username"
              type="text"
              placeholder={this.props.i18n.getUsernamePlaceholder(
                country,
                this.state?.consultantDocument
              )}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  username: event.target.value,
                  usernameState: "",
                  usernameHelpText: "",
                })
              }
              state={this.state?.usernameState}
              helpText={this.state?.usernameHelpText}
              onKeyDown={this.handleOnKeyDown}
              value={this.state?.username ?? ""}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={getErrorText(country)}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showResendTemporaryPasswordLink && (
            <Link
              text={this.props.i18n.get("Resend first access email")}
              url={this.buildNavigationLink("resend-temporary-password")}
            />
          )}
        </div>
        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginButtonStyling(country)
                : getFirstAccessButtonStyling(country)
            }
          >
            <Button
              id="forgotPasswordButton"
              onClick={async (): Promise<void> => {
                await this.onForgotPasswordButtonClick();
              }}
              text={this.props.i18n.get("Send")}
              itemRef={this.refButton}
            />
          </div>
        </div>
        {country === "co" && (
          <div style={{ margin: "10px" }}>
            <a
              style={{
                fontSize: "14px",
                // eslint-disable-next-line spellcheck/spell-checker
                fontFamily: "Roboto",
                fontWeight: "bold",
              }}
              // eslint-disable-next-line spellcheck/spell-checker
              href="https://www.youtube.com/watch?v=Mb6EaGAAd9k"
              target="_blank"
              // eslint-disable-next-line spellcheck/spell-checker
              rel="noopener noreferrer"
            >
              {this.props.i18n.get("Watch a video of the process.")}
            </a>
          </div>
        )}
      </Container>
    );
  }

  private async onForgotPasswordButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        showResendTemporaryPasswordLink: false,
      });

      if (!this.validUserInput()) {
        return Promise.resolve();
      }
      const encryptedUsername = crypto.AES.encrypt(
        this.state?.username?.replace(/ /g, "") as string,
        environment.encryptionKey
      ).toString();
      const userInfo = await this.tryFetchUserInfo(encryptedUsername);

      if (userInfo.status === "FORCE_CHANGE_PASSWORD") {
        this.showUserNotConfirmedError();
        this.setState({
          showResendTemporaryPasswordLink: true,
        });
      } else if (countriesWithSMSReset.includes(this.state?.country)) {
        const { email, phoneNumber, userNotFound } =
          await this.tryFetchUserContacts(encryptedUsername);
        if (userNotFound) {
          return this.requestForgotPassword(encryptedUsername, "EMAIL");
        }
        const hasEmail = email !== undefined;
        const hasPhoneNumber = phoneNumber !== undefined;
        if (hasEmail && hasPhoneNumber) {
          this.setState({
            showSendOptions: true,
            email,
            phoneNumber,
          });
        } else if (hasPhoneNumber) {
          this.requestForgotPassword(encryptedUsername, "SMS");
        } else if (hasEmail) {
          this.requestForgotPassword(encryptedUsername, "EMAIL");
        } else {
          this.showEmptyContactsError();
        }
      } else {
        this.requestForgotPassword(encryptedUsername, "EMAIL");
      }
    } catch (err) {
      this.handleApiErrors(err as ApiError);
    }
    return Promise.resolve();
  }

  private async requestForgotPassword(
    encryptedUsername: string,
    medium?: "SMS" | "EMAIL"
  ): Promise<void> {
    try {
      const result = await this.props.api.forgotPassword({
        country: this.state?.country as string,
        company: this.state?.company as string,
        username: encryptedUsername,
        clientId: this.state?.clientId as string,
        redirectUrl: this.state?.redirectUri as string,
        state: this.buildForgotPasswordUrlState(),
        desiredDeliveryMediums: medium,
      } as ForgotPasswordBody);

      if (result.wasForcedCreated) {
        this.navigate("");
      } else {
        this.setState({
          destination: result.destination,
        });
        if (this.state?.country?.toLowerCase() === "co") {
          this.setState({
            previousPage: "forgot-password",
          });
          this.navigate("redirect", this.state);
        } else {
          this.navigate("confirm-forgot-password", this.state);
        }
      }
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    }
  }

  private handleApiErrors(error: ApiError) {
    if (isTooManyRequestsError(error)) {
      this.showPasswordAttemptsExceededError();
    } else if (this.isInvalidError(error)) {
      this.showInvalidError();
    } else if (this.isEmailEmptyError(error)) {
      this.showEmptyEmailError();
    } else if (this.isRoleError(error)) {
      this.showRoleError();
    } else if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else {
      this.showGenericError();
    }
  }

  private buildForgotPasswordUrlState(): string {
    return `client_id=${this.state.clientId}&country=${this.state.country}&company=${this.state.company}&language=${this.state.language}&redirect_uri=${this.state.redirectUri}&username=${this.state.username}`;
  }

  private async tryFetchUserInfo(
    encryptedUsername: string
  ): Promise<FetchUserInfoResponse> {
    try {
      return await this.props.api.fetchUserInfo({
        country: this.state?.country as string,
        company: this.state?.company as string,
        username: encryptedUsername,
        clientId: this.state?.clientId as string,
      });
    } catch (error) {
      return {
        status: "NOT_FOUND",
      } as FetchUserInfoResponse;
    }
  }

  private async tryFetchUserContacts(
    encryptedUsername: string
  ): Promise<FetchUserContactsResponse> {
    try {
      return await this.props.api.fetchUserContacts({
        country: this.state?.country as string,
        company: this.state?.company as string,
        username: encryptedUsername,
        clientId: this.state?.clientId as string,
      });
    } catch (e) {
      const error = e as ApiError;
      if (this.isInvalidError(error) || this.isNotFoundError(error)) {
        return { userNotFound: true } as FetchUserContactsResponse;
      } else {
        return {} as FetchUserContactsResponse;
      }
    }
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private setUsernameError() {
    this.setState({
      usernameHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get(
          this.props.i18n.getUsernamePlaceholder(this.state?.country)
        )
      ),
      usernameState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }

  private isRoleError(error: Error): boolean {
    return error.message.toLowerCase().includes("role not allowed");
  }

  private isEmailEmptyError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return message.includes("email is empty") || message.includes("no email");
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      error.status === 404 ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private isInvalidError(error: ApiError): boolean {
    return error.message
      .toLowerCase()
      .includes("user cannot perform this action");
  }

  private showPasswordAttemptsExceededError() {
    this.showError(attemptLimitExceededMessage());
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showEmptyContactsError() {
    this.showError("Empty email and cell phone number");
  }

  private showRoleError() {
    this.showError("The user does not have a valid role");
  }

  private showEmptyEmailError() {
    this.showError("Empty email");
  }

  private showUserNotConfirmedError() {
    this.showError(
      "You still not make your first access, check your email to see if you already have an temporary password, or request a new one on the link below"
    );
  }

  private showNotFoundError() {
    this.showError("User does not exists");
  }

  private showInvalidError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "The user cannot perform this action"
      ),
      formHelperState: "error",
    });
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(message),
      formHelperState: "error",
    });
  }

  private MenuDocumentsData(): React.JSX.Element {
    const country = this.state?.country;
    const data = getLoginDocumentsData(country);

    if (
      !(
        data !== undefined &&
        environment.loginByDocumentCountries?.includes(country)
      )
    ) {
      return <></>;
    }

    const options = getConsultantDocumentsSelectList(data);
    return (
      <div className="row" style={styles.centerRow}>
        <div style={styles.select}>
          <Select
            defaultValue={getLoginDefaultSelectValue(country)}
            onChange={(event: ChangeEvent<{ value: unknown }>) => {
              this.setState({
                consultantDocument: event.target.value as string,
                consultantDocumentSelectState: undefined,
                consultantDocumentHelpText: "",
              });
            }}
            options={options}
            state={this.state?.consultantDocumentSelectState}
            helpText={this.state?.consultantDocumentHelpText}
          />
        </div>
      </div>
    );
  }
}
