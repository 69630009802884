import Typography from "@material-ui/core/Typography";
import { ReactNode } from "react";
import { Country } from "src/interfaces/country";

type Props = {
  children?: ReactNode;
  enabledCountries: Country[];
  country: Country;
};

const ChooseYourIdentityText: React.FC<Props> = ({
  enabledCountries,
  country,
  children,
}) => {
  if (enabledCountries?.includes(country)) {
    return (
      <Typography
        variant="h1"
        style={{
          fontSize: "16px",
          color: "#333333",
          fontWeight: 500,
          marginBottom: "10px",
        }}
      >
        {children}
      </Typography>
    );
  }
  return null;
};

export default ChooseYourIdentityText;
