import { ProgressIndicator } from "@naturacosmeticos/natds-web";
import styles from "../styles/styles";
import { useContext } from "react";
import { getTranslation } from "src/I18n/I18n";
import { AppContext } from "src/utils/context";

export const Loading = (): React.JSX.Element => {
  const { i18n } = useContext(AppContext);
  return (
    <div
      style={{
        ...styles.alingCenter,
        flexDirection: "column",
        maxHeight: "300px",
        height: "100%",
      }}
    >
      <div style={styles.smallMargin}>
        <ProgressIndicator size={64} />
      </div>
      <div style={styles.smallMargin}>{getTranslation(i18n, "Loading")}...</div>
      <div style={styles.smallMargin}>
        {getTranslation(i18n, "Please, wait a moment.")}
      </div>
    </div>
  );
};
