import { Country } from "src/interfaces/country";
import { SMSConfig } from "src/interfaces/sms-config";
import { countries } from "./country-list";

export const parseSMSConfig = (config?: string): SMSConfig => {
  const configObj = JSON.parse(config || "{}") as SMSConfig;
  const smsConfig: SMSConfig = {};
  const resendCountries = configObj.resend?.countries ?? [];
  const resetCountries = configObj.reset?.countries ?? [];
  if (resendCountries.length > 0) {
    smsConfig.resend = { countries: filterValidCountries(resendCountries) };
  }
  if (resetCountries.length > 0) {
    smsConfig.reset = { countries: filterValidCountries(resetCountries) };
  }
  return smsConfig;
};

export const parseCountryList = (config?: string): Country[] => {
  const configArray = JSON.parse(config || "[]") as string[];
  return filterValidCountries(configArray);
};

const filterValidCountries = (list: string[]): Country[] =>
  list
    .map((country) => country.toLowerCase() as Country)
    .filter((country) => countries.includes(country));
