import React from "react";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@naturacosmeticos/natds-web";
import { IAuthPieceProps } from "src/pages/auth-piece";
import { Button } from "./button";
import { Country } from "src/interfaces/country";
import { cellPhoneSVG } from "src/assets/phones/cellphone";
import { phoneSVG } from "src/assets/phones/phone";

export interface IUserContactsModalProps extends IAuthPieceProps {
  title: string;
  visible?: boolean;
  email?: string;
  phoneNumber?: string;
  country?: Country;
  // eslint-disable-next-line no-unused-vars
  onSelect: (medium: "SMS" | "EMAIL") => Promise<void>;
  onCancel: () => void;
}

const sendButtonContainerStyle: React.CSSProperties = {
  width: "45%",
  padding: "0.5rem",
  display: "inline-flex",
  marginBottom: "1rem",
} as React.CSSProperties;

const sendButtonStyle: React.CSSProperties = {
  height: "3.5rem",
};

const optionsContainerStyle: React.CSSProperties = {
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
};

export const UserContactsModal = ({
  title,
  visible = false,
  email,
  phoneNumber,
  country,
  onSelect,
  onCancel,
  i18n,
}: IUserContactsModalProps) => (
  <Dialog
    open={visible}
    onClose={onCancel}
    aria-labelledby="alert-dialog-title"
    aria-describedby="alert-dialog-description"
  >
    <DialogTitle>{i18n.get(title)}</DialogTitle>
    <DialogContent style={{ textAlign: "center" }}>
      <div style={optionsContainerStyle}>
        {phoneNumber ? (
          <Button
            containerStyle={sendButtonContainerStyle}
            style={sendButtonStyle}
            text={`SMS: ${phoneNumber}`}
            variant="outlined"
            onClick={async () => await onSelect("SMS")}
          />
        ) : null}
        {email ? (
          <Button
            containerStyle={sendButtonContainerStyle}
            style={sendButtonStyle}
            text={`Email: ${email}`}
            variant="outlined"
            onClick={async () => await onSelect("EMAIL")}
          />
        ) : null}
      </div>
      <div>{getDialogContentText(country)}</div>
    </DialogContent>
    <DialogActions>
      <Button
        variant="text"
        onClick={async () => onCancel()}
        text={i18n.get("Cancel")}
      />
    </DialogActions>
  </Dialog>
);

const getDialogContentText = (country?: Country) => {
  let text = (
    <span>
      You do not recognize that cell phone or e-mail? Contact customer service
    </span>
  );

  switch (country) {
    case "pe":
      text = (
        <span>
          ¿No reconoces el celular o correo electrónico? Por favor, actualiza
          tus datos a través de nuestras líneas de atención:
          <div>
            <img src={phoneSVG} alt="" height="15px" width="16px" /> 0800-80-510
            para llamadas desde teléfono fijo
          </div>
          <div>
            <img src={cellPhoneSVG} alt="" height="15px" width="16px" />{" "}
            01700-9300 para llamadas desde teléfono celular
          </div>
        </span>
      );
      break;
    case "co":
      text = (
        <span>
          Si no reconoces ese celular o correo, actualízalos en{" "}
          {
            // eslint-disable-next-line spellcheck/spell-checker
            <a href="http://www.minegocio.be/actualizar">
              www.minegocio.be/actualizar
            </a>
          }{" "}
          o comunícate al fijo 6015188505 y 018000969010
        </span>
      );
      break;
    default:
      break;
  }

  return text;
};
