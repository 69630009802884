import React, { ChangeEvent } from "react";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { Input } from "src/components";
import { Button } from "src/components/button";
import { Container } from "src/components/container";
import styles from "src/styles/styles";
import { isNotEmpty } from "src/utils/is-not-empty";
import { decideQuerySeparator } from "src/utils/query-builder";
import { redirect } from "src/utils/redirect";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { ApiError } from "src/interfaces/api-error";
import { PasswordInput } from "src/components/password-input-br";
import { showCountryFlag } from "src/utils/elo-utils";
import { PasswordPolicyConfig } from "src/api/interfaces/fetch-password-policy-response";
import crypto from "crypto-js";

export interface IFirstAccessBRPieceState extends IAuthPieceState {
  session: string;
  username: string;
  newPassword: string;
  confirmNewPassword: string;
  newPasswordState?: string;
  newPasswordHelpText?: string;
  confirmNewPasswordState?: string;
  confirmNewPasswordHelpText?: string;
  formHelperErrorMessage?: string;
  formHelperState?: string;
  anchorEl?: HTMLButtonElement;
  openPopover?: boolean;
  passwordPolicy?: PasswordPolicyConfig[];
}

const FIELD_COULD_NOT_BE_EMPTY = "Field '###' could not be empty";

export class FirstAccessBR extends AuthPiece<
  IAuthPieceProps,
  IFirstAccessBRPieceState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IAuthPieceProps) {
    super(props);
    this.getNewPasswordFromInput = this.getNewPasswordFromInput.bind(this);
    this.getConfirmPasswordFromInput =
      this.getConfirmPasswordFromInput.bind(this);
    this.handleInfoButtonClick = this.handleInfoButtonClick.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.handleNewPasswordOnChange = this.handleNewPasswordOnChange.bind(this);
    this.handleConfirmNewPasswordOnChange =
      this.handleConfirmNewPasswordOnChange.bind(this);
    this.getPasswordInputs = this.getPasswordInputs.bind(this);

    this.refButton = React.createRef();
  }

  public getNewPasswordFromInput(): string | undefined {
    return this.state.newPassword;
  }

  public getConfirmPasswordFromInput(): string | undefined {
    return this.state.confirmNewPassword;
  }

  private handleInfoButtonClick(
    event: React.MouseEvent<HTMLButtonElement>
  ): void {
    this.setState({
      openPopover:
        this.state?.anchorEl !== event.currentTarget
          ? true
          : !this.state?.openPopover,
      anchorEl: event.currentTarget,
    });
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    await this.loadBRPasswordPolicy();
  }

  private async loadBRPasswordPolicy(): Promise<void> {
    const country = this.state?.country as string;
    const company = this.state?.company;
    const language = this.state?.language as string;

    try {
      const result = await this.props.api.fetchPasswordPolicyCache({
        country,
        company,
        language,
      });
      this.setState({
        passwordPolicy: result[language]?.[company]?.[country] ?? [],
      });
    } catch (err) {
      const apiError = err as ApiError;
      console.error("Unable to load password rules:", apiError.message);
    }
  }

  private getPasswordInputs(): React.JSX.Element | null {
    return (
      <>
        <PasswordInput
          className="row"
          style={styles.centerRow}
          i18n={this.props.i18n}
          placeholder="New password"
          helpText={this.state?.newPasswordHelpText}
          value={this.state?.newPassword}
          state={this.state?.newPasswordState}
          onKeyDown={this.handleOnKeyDown}
          onChange={this.handleNewPasswordOnChange}
          passwordPolicy={this.state?.passwordPolicy}
          disabled={!this.state?.passwordPolicy}
        />
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container passwordInput">
            <Input
              id="confirmNewPassword"
              type="password"
              placeholder={this.props.i18n.get("Confirm password")}
              onChange={this.handleConfirmNewPasswordOnChange}
              helpText={this.state?.confirmNewPasswordHelpText}
              state={this.state?.confirmNewPasswordState}
              onKeyDown={this.handleOnKeyDown}
              disabled={!this.state?.passwordPolicy}
            />
          </div>
        </div>
      </>
    );
  }

  public render(): React.JSX.Element {
    return (
      <Container
        country={showCountryFlag(this.state?.country)}
        company={this.state?.company}
      >
        <div
          className="row"
          style={{ ...styles.centerRow, textAlign: "center" }}
        >
          <div className="natds-input-container">
            <Typography variant="caption" style={{ fontSize: "16px" }}>
              {this.props.i18n.get(
                `Fill in the fields below to create a new password`
              )}
            </Typography>
          </div>
        </div>

        {this.getPasswordInputs()}

        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container" style={styles.centerRow}>
            <FormHelperText
              state={this.state?.formHelperState as State}
              style={styles.helperText}
            >
              {this.state?.formHelperErrorMessage}
            </FormHelperText>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div className="natds-input-container">
            <Button
              id="firstAccessButton"
              onClick={async (): Promise<void> => {
                await this.onButtonClick();
              }}
              text={this.props.i18n.get("create new password")}
              itemRef={this.refButton}
              disabled={
                !this.state?.newPassword ||
                this.state?.newPasswordState === "error" ||
                !this.state?.confirmNewPassword ||
                this.state?.confirmNewPasswordState === "error"
              }
            />
          </div>
        </div>
      </Container>
    );
  }

  private handleNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>,
    newPasswordState = "",
    newPasswordHelpText = ""
  ): void {
    const newPassword = event.target.value;

    this.setState({
      newPassword,
      newPasswordHelpText,
      newPasswordState,
    });
    this.validateConfirmNewPassword(
      newPassword,
      this.state.confirmNewPassword ?? ""
    );
  }

  private handleConfirmNewPasswordOnChange(
    event: ChangeEvent<HTMLInputElement>
  ): void {
    const confirmNewPassword = event.target.value;

    this.setState({
      confirmNewPassword,
    });
    this.validateConfirmNewPassword(
      this.state.newPassword ?? "",
      confirmNewPassword
    );
  }

  private validateConfirmNewPassword(
    newPassword: string,
    confirmNewPassword: string
  ): void {
    if (confirmNewPassword) {
      const confirmNewPasswordState =
        confirmNewPassword === newPassword ? "success" : "error";
      const confirmNewPasswordHelpText =
        confirmNewPasswordState === "error"
          ? this.props.i18n.get("Passwords do not match")
          : "";

      this.setState({
        confirmNewPasswordState,
        confirmNewPasswordHelpText,
      });
    } else {
      this.setConfirmNewPasswordError();
    }
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.setState({ formHelperErrorMessage: "", formHelperState: "" });
      if (!this.validateInput()) {
        return;
      }
      const encryptedPass = crypto.AES.encrypt(
        this.state?.newPassword?.replace(/ /g, ""),
        this.state?.username?.replace(/ /g, "")
      ).toString();

      const result = await this.props.api.resolveFirstAccessChallenge({
        clientId: this.state?.clientId as string,
        country: this.state?.country as string,
        company: this.state?.company as string,
        session: this.state?.session as string,
        username: this.state?.username?.replace(/ /g, "") as string,
        newPassword: encryptedPass,
        redirectUrl: this.state.redirectUri,
      });
      await this.saveUserSession(result.sso_token);

      this.setState({
        previousPage: "first-access",
      });
      this.navigate("redirect", this.state);
    } catch (error) {
      const apiError = error as ApiError;
      if (apiError.status === 400) {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(apiError.message),
          formHelperState: "error",
        });
      } else {
        this.setState({
          formHelperErrorMessage: this.props.i18n.get(
            "Failed to set new password"
          ),
          formHelperState: "error",
        });
      }
    }
  }

  private validateInput(): boolean {
    let isFormValid = true;
    if (!isNotEmpty(this.state?.newPassword)) {
      this.setNewPasswordError();
      isFormValid = false;
    }
    if (!isNotEmpty(this.state?.confirmNewPassword)) {
      this.setConfirmNewPasswordError();
      isFormValid = false;
    }
    if (this.state?.newPassword !== this.state?.confirmNewPassword) {
      this.setPasswordsMismatchError();
      isFormValid = false;
    }
    return isFormValid;
  }

  private setNewPasswordError() {
    this.setState({
      newPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("New password")
      ),
      newPasswordState: "error",
    });
  }

  private setConfirmNewPasswordError() {
    this.setState({
      confirmNewPasswordHelpText: this.props.i18n.getByTemplate(
        FIELD_COULD_NOT_BE_EMPTY,
        this.props.i18n.get("Confirm new password")
      ),
      confirmNewPasswordState: "error",
    });
  }

  private setPasswordsMismatchError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get("Passwords do not match"),
      formHelperState: "error",
    });
  }

  private goBackToApplication(ssoToken: string): void {
    redirect(
      `${this.state.redirectUri}${decideQuerySeparator(
        this.state.redirectUri
      )}sso_token=${ssoToken}`
    );
  }

  private async saveUserSession(ssoToken: string): Promise<void> {
    const cookieName = this.getCookieName();
    this.props.cookies.createCookie(cookieName, ssoToken);
  }

  private getCookieName(): string {
    return `${this.state?.country}_${this.state?.company}_id`;
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      await this.refButton.current?.click();
    }
  }
}
