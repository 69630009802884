import { environment } from "../config/environment";
import { BffApi } from "./bff-api";

export const getApiDomain = (isBR: boolean): string => {
  if (
    environment.environment === "prd" &&
    window.location.hostname === environment.prePrdHostname
  ) {
    const domain = isBR
      ? environment.apiPrePrdDomainBR
      : environment.apiPrePrdDomain;
    if (domain !== undefined) {
      return domain;
    }
  }
  return isBR ? environment.apiDomainBR : environment.apiDomain;
};

export const createAPIInstance = (isBR: boolean): BffApi =>
  new BffApi(getApiDomain(isBR), environment.apiToken);
