import React from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import { FormHelperText, Typography } from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { eloIsEnabled, showCountryFlag } from "src/utils/elo-utils";

export type IRedirectToLoginProps = IAuthPieceProps;

export interface IRedirectToLoginState extends IAuthPieceState {
  formHelperState?: string;
  formHelperErrorMessage?: string;
  previousPage?: string;
}

export class RedirectToLogin extends AuthPiece<
  IRedirectToLoginProps,
  IRedirectToLoginState
> {
  public constructor(props: IRedirectToLoginProps) {
    super(props);

    this.showInstructions = this.showInstructions.bind(this);
    this.getMessageAfterConfirmForgotPassword =
      this.getMessageAfterConfirmForgotPassword.bind(this);
  }

  public render(): React.JSX.Element {
    return (
      <Container
        country={showCountryFlag(this.state?.country)}
        company={this.state?.company}
      >
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Typography color="textPrimary">
              {this.showInstructions()}
            </Typography>
          </div>
        </div>
        {(this.state?.country?.toLowerCase() !== "my" ||
          this.state?.previousPage === "resend-temporary-password") && (
          <div className="row" style={styles.centerRow}>
            <div style={{ margin: "20px", width: "300px" }}>
              <Button
                onClick={async (): Promise<void> => {
                  await this.onButtonClick();
                }}
                text={this.getButtonMessage()}
              />
            </div>
          </div>
        )}
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.helperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
      </Container>
    );
  }

  private async onButtonClick(): Promise<void> {
    try {
      this.navigate("");
    } catch (error) {
      this.setState({
        formHelperErrorMessage: this.props.i18n.get(
          "An error occour on the redirect"
        ),
        formHelperState: "error",
      });
    }
  }

  private getButtonMessage(): string {
    if (this.state?.previousPage === "forgot-password") {
      return this.props.i18n.get("Understood");
    }
    return this.props.i18n.get("Go back to Login");
  }

  private showInstructions(): string {
    switch (this.state?.previousPage) {
      case "confirm-forgot-password":
      case "first-access":
      case "change-password":
        return this.getMessageAfterConfirmForgotPassword(this.state?.country);
      case "resend-temporary-password":
      case "forgot-password-resend-temporary-password":
        return this.props.i18n.get(
          `An e-mail was sent with your temporary password, please check your email an go back to login page by clicking in the button below`
        );
      case "forgot-password":
        return `${this.props.i18n.get(
          `Instructions to create a new password have been sent to the email registered in ${
            eloIsEnabled(this.state?.country) ? "Natura and Avon" : "Natura"
          }`
        )}: ${this.state?.destination}`;
      default:
        return `You can now go back to the login page`;
    }
  }

  private getMessageAfterConfirmForgotPassword(country: string): string {
    if (country.toLowerCase() === "my") {
      return this.props.i18n.get(
        `Your password has been successfully updated, please return to the NaturaOn app`
      );
    } else {
      return this.props.i18n.get(
        `Your password was updated with success, please click in the button below to go back to login page`
      );
    }
  }
}
