import React from "react";
import styles from "../styles/styles";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button } from "../components";
import { Typography } from "@naturacosmeticos/natds-web";
import { Container } from "src/components/container";
import { IOTPTokenState } from "./otp-token";

export type IErrorValidateProps = IAuthPieceProps;

export interface IErrorValidateState extends IAuthPieceState {
  message?: string;
}

export class ErrorValidateOTP extends AuthPiece<
  IErrorValidateProps,
  IErrorValidateState
> {
  public constructor(props: IErrorValidateProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();

    this.setState({ message: this.state.errorDescription || "" });
    const state = this.props.location.state as IOTPTokenState;
    this.setState({
      message: state.formHelperErrorMessage as string,
    });
  }

  public render(): React.JSX.Element {
    return (
      <Container company={this.state?.company}>
        <div className="row" style={styles.centerRow}>
          <Typography
            variant="caption"
            style={{
              fontSize: "26px",
              marginBottom: "10px",
              fontWeight: "700",
              letterSpacing: 0,
            }}
          >
            {this.props.i18n.get("Verification")}
          </Typography>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Typography color="textPrimary">
              {this.props.i18n.get(
                this.state?.message ??
                  "We were unable to process your request. Please try again later"
              )}
            </Typography>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Button
              onClick={async (): Promise<void> => {
                this.navigate("");
              }}
              text={this.props.i18n.get("Go back to Login")}
            />
          </div>
        </div>
      </Container>
    );
  }
}
