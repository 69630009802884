import Cookies from "universal-cookie";

export class CookiesService {
  public createCookie(name: string, content: any): void {
    const cookies = new Cookies();
    cookies.set(name, content, { path: "/", maxAge: 86400 });
  }

  public createOrUpdateCookie(name: string, content: any): void {
    const cookies = new Cookies();
    const cookie = cookies.get(name);
    cookies.set(name, { ...cookie, ...content }, { path: "/", maxAge: 86400 });
  }

  public getCookie(name: string): any {
    const cookies = new Cookies();
    return cookies.get(name);
  }

  public removeCookie(name: string): void {
    const cookies = new Cookies();
    cookies.remove(name, {
      path: "/",
    });
  }
}
