import { useState } from "react";
import VerificationInput from "react-verification-input";
import "./style.css";

interface InputVerificationCodeProps {
  // eslint-disable-next-line no-unused-vars
  onChange: (value: string) => void;
  value: string;
  state: string;
}

const baseClassName = "verification-input";

export const InputVerificationCode = ({
  onChange,
  value,
  state,
}: InputVerificationCodeProps): React.JSX.Element => {
  const [isFocused, setIsFocused] = useState(false);

  let className = baseClassName;

  if (state === "error") {
    className = `${baseClassName} error`;
  } else if (isFocused) {
    className = `${baseClassName} focused`;
  } else if (state === "success") {
    className = `${baseClassName} success`;
  }

  return (
    <div className={className}>
      <VerificationInput
        validChars="0-9"
        inputProps={{ inputMode: "numeric" }}
        autoFocus
        placeholder=""
        onChange={onChange}
        value={value}
        onFocus={() => setIsFocused(true)}
        onBlur={() => setIsFocused(false)}
        classNames={{
          character: "character",
          container: "container",
          characterInactive: "character-inactive",
          characterSelected: "character-selected",
        }}
      />
    </div>
  );
};
