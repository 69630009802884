import React from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import {
  Icon,
  Popover,
  ProgressIndicator,
  Typography,
} from "@naturacosmeticos/natds-web";
import { ApiError } from "src/interfaces/api-error";
import parse from "html-react-parser";
import { AppContext, FederationExtraInfo, OTPFlow } from "src/utils/context";
import { InputVerificationCode } from "src/components/InputVerificationCode";
import { getFederationUrl, getProviderName } from "src/utils/federation-utils";
import { redirect } from "src/utils/redirect";

export type IOTPTokenProps = IAuthPieceProps;

export interface IOTPTokenState extends IAuthPieceState {
  username?: string;
  consultantDocument?: string;
  email?: string;
  phoneNumber?: string;
  channel?: string;
  resendTime?: number;
  interval?: number;
  token?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string | React.JSX.Element | React.JSX.Element[];
  anchorEl?: HTMLElement;
  handleKeyUp?: React.KeyboardEvent;
  showLoginLink?: boolean;
}

const RESEND_SECONDS = 60;

export class OTPToken extends AuthPiece<IOTPTokenProps, IOTPTokenState> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  static readonly contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;

  public constructor(props: IOTPTokenProps) {
    super(props);
    this.createTimer = this.createTimer.bind(this);
    this.clearTimer = this.clearTimer.bind(this);
    this.refButton = React.createRef();
    this.handleKeyUp = this.handleKeyUp.bind(this);
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    const { username, consultantDocument, email, phoneNumber, channel } =
      this.context.otpContext;
    this.setState({
      email: email ? this.shortenEmail(email) : undefined,
      phoneNumber,
      username,
      consultantDocument,
      channel,
      showLoginLink: false,
    });
    this.createTimer();
    document.addEventListener("keyup", this.handleKeyUp);
  }

  componentDidUpdate(previousState: any) {
    const { token } = this.state;
    if (token !== previousState.token && (token as string).length > 5) {
      document.removeEventListener("keyup", this.handleKeyUp);
      document.addEventListener("keyup", this.handleKeyUp);
    }
  }
  componentWillUnmount() {
    this.clearTimer();
    document.removeEventListener("keyup", this.handleKeyUp);
  }

  private createTimer(): void {
    this.setState({ resendTime: RESEND_SECONDS });
    const interval = window.setInterval(() => {
      if (this.state?.resendTime === 0) {
        this.clearTimer();
        this.setState({ resendTime: undefined, interval: undefined });
        return;
      }
      this.setState((state) => ({ resendTime: (state.resendTime ?? 1) - 1 }));
    }, 1000);
    this.setState({ interval });
  }

  private clearTimer(): void {
    if (this.state.interval !== undefined) {
      window.clearTimeout(this.state.interval);
    }
  }

  private handleKeyUp(event: any) {
    const code = this.state?.token as string;
    if (event.key.toLowerCase() === "enter" && code.length > 5) {
      this.onVerifyButtonClick();
      document.removeEventListener("keyup", this.handleKeyUp);
    }
  }

  public render(): React.JSX.Element {
    const hasError = this.state?.formHelperState === "error";
    return (
      <Container company={this.state?.company}>
        {!hasError ? (
          <div className="row" style={styles.centerRow}>
            <Typography
              variant="caption"
              style={{
                fontSize: "26px",
                marginBottom: "10px",
                fontWeight: "700",
                letterSpacing: 0,
              }}
            >
              {this.props.i18n.get("Verification")}
            </Typography>
          </div>
        ) : null}
        <Icon
          size="largeX"
          name={
            hasError
              ? "outlined-alert-warning"
              : this.state?.channel === "EMAIL"
              ? "outlined-communication-readEmail"
              : "outlined-media-app"
          }
          color={hasError ? "error" : "default"}
        />
        {this.subTitle()}
        <div className="row" style={styles.centerRow}>
          <div style={styles.inputContainer}>
            <InputVerificationCode
              value={this.state?.token ?? ""}
              onChange={(value) => {
                this.setState({
                  token: value,
                  formHelperState: "",
                });
              }}
              state={this.state?.formHelperState ?? ""}
            />
          </div>
        </div>

        <div className="row" style={styles.centerRow}>
          <div style={{ width: "328px", margin: "10px" }}>
            {!this.state?.isLoading ? <></> : <ProgressIndicator size={48} />}
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div
            className="natds-input-container"
            style={{ minWidth: 200, width: 200 }}
            onMouseEnter={(event) =>
              this.setState({ anchorEl: event.currentTarget })
            }
            onMouseLeave={() => this.setState({ anchorEl: undefined })}
          >
            <Popover
              open={this.state?.anchorEl !== undefined}
              maxWidth={0}
              anchorEl={this.state?.anchorEl}
              placement="bottom"
            >
              {this.props.i18n.get(
                "To resend another code, wait the necessary time."
              )}
            </Popover>
            <Button
              id="resendButton"
              onClick={async (): Promise<void> => {
                await this.onResendButtonClick();
              }}
              text={
                this.props.i18n.get("Resend Code") +
                (this.state?.resendTime !== undefined &&
                this.state?.resendTime > 0
                  ? ` (${this.state?.resendTime})`
                  : "")
              }
              disabled={
                this.state?.resendTime !== undefined &&
                this.state?.resendTime > 0
              }
            />
          </div>
          <div
            className="natds-input-container"
            style={{ minWidth: 100, width: 100 }}
          >
            <Button
              id="verifyButton"
              onClick={async (): Promise<void> => {
                await this.onVerifyButtonClick();
              }}
              text={this.props.i18n.get("verify")}
              itemRef={this.refButton}
              disabled={!this.state?.token || this.state?.token.length < 6}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showLoginLink && (
            <div style={{ margin: "20px", width: "300px" }}>
              <Button
                onClick={async (): Promise<void> => {
                  this.navigate("");
                }}
                text={this.props.i18n.get("Go back to Login")}
              />
            </div>
          )}
        </div>
      </Container>
    );
  }

  private async onVerifyButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        isLoading: true,
        showLoginLink: false,
      });

      const result = await this.props.api.tokenValidate({
        country: this.state?.country as string,
        company: this.state?.company,
        clientId: this.state?.clientId,
        redirectUrl: this.state?.redirectUri,
        username: this.state?.username?.replace(/ /g, "") as string,
        document: this.state?.consultantDocument,
        token: this.state?.token as string,
      });

      this.context.otpContext.session = result.session;

      const previousPage = "otp-token";
      this.setState({
        previousPage,
      });

      const { flow, flowExtraInfo } = this.context.otpContext;

      if (flow === OTPFlow.FEDERATION_ERROR && flowExtraInfo !== undefined) {
        await this.linkFederation(result.session, flowExtraInfo);
        return;
      }
      this.navigate("otp-password", { ...this.state, previousPage });
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private async linkFederation(
    session: string,
    federationExtraInfo: FederationExtraInfo
  ): Promise<void> {
    await this.props.api.otpLinkFederation({
      country: this.state?.country as string,
      company: this.state?.company as string,
      clientId: this.state?.clientId as string,
      redirectUrl: this.state?.redirectUri,
      username: this.state?.username?.replace(/ /g, "") as string,
      document: this.state?.consultantDocument,
      socialSignInId: federationExtraInfo.key,
      session: session,
    });

    const country = this.state?.country as string;
    const providerName = getProviderName(federationExtraInfo.provider, country);
    const federationUrl = getFederationUrl(
      country,
      providerName,
      this.state?.clientId as string
    );
    this.context.otpContext.reset();
    redirect(federationUrl);
  }

  private async onResendButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        isLoading: true,
      });

      await this.props.api.tokenSend({
        country: this.state?.country as string,
        company: this.state?.company,
        clientId: this.state?.clientId,
        redirectUrl: this.state?.redirectUri,
        username: this.state?.username?.replace(/ /g, "") as string,
        document: this.state?.consultantDocument,
        channel: this.state?.channel as string,
      });
      this.createTimer();
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleApiErrors(error: ApiError): void {
    if (this.isInvalidTokenError(error)) {
      this.showInvalidTokenError();
    } else if (this.isFederationError(error)) {
      this.showFederationError();
    } else if (this.isSocialLoginExpiredError(error)) {
      this.showSocialLoginExpiredError();
    } else if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else if (this.MaximumInvalidOTPError(error)) {
      this.showMaximumInvalidOTPError();
    } else if (this.OTPSessionError(error)) {
      this.showOTPSessionError();
    } else if (this.isRoleError(error)) {
      this.showRoleError();
    } else if (this.isEmailEmptyError(error)) {
      this.showEmptyEmailError();
    } else {
      this.showGenericError();
    }
  }

  private isInvalidTokenError(error: Error): boolean {
    const message = error.message.toLowerCase();
    return (
      message.includes("invalid token") ||
      message.includes("error validating token")
    );
  }

  private showInvalidTokenError() {
    this.showError("Invalid code. Please check and try again.");
    this.setState({
      showLoginLink: true,
    });
  }

  private isFederationError(error: Error): boolean {
    const message = error.message.toLowerCase();
    return message.includes("failed to create user federation");
  }

  private showFederationError() {
    this.showError("Failed to create the link for the user");
    this.setState({
      showLoginLink: true,
    });
  }

  private isSocialLoginExpiredError(error: Error): boolean {
    const message = error.message.toLowerCase();
    return message.includes("social login expired");
  }

  private showSocialLoginExpiredError() {
    this.showError("Session expired, please try to login again");
    this.setState({
      showLoginLink: true,
    });
  }

  private isEmailEmptyError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      message.includes("email is empty") ||
      message.includes("no email") ||
      message.includes("valid e-mail")
    );
  }

  private showEmptyEmailError() {
    this.showError("Empty email");
  }

  private isRoleError(error: Error): boolean {
    const message = error.message.toLowerCase();
    return (
      message.includes("role not allowed") ||
      message.includes("does not have a valid role")
    );
  }

  private showRoleError() {
    this.showError("The user does not have a valid role");
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      (error.status === 404 && !message.includes("expired session")) ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showNotFoundError() {
    this.showError("User does not exists");
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: parse(this.props.i18n.get(message)),
      formHelperState: "error",
    });
  }

  private MaximumInvalidOTPError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return message.includes("reached maximum invalid tokens.");
  }

  private showMaximumInvalidOTPError() {
    this.setState({
      formHelperErrorMessage: "Reached maximum invalid tokens.",
    });
    this.navigate("error-validate-otp", { ...this.state });
  }

  private OTPSessionError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return message.includes("expired session");
  }

  private showOTPSessionError() {
    this.setState({
      formHelperErrorMessage: "Expired session.",
    });
    this.navigate("error-validate-otp", { ...this.state });
  }

  private subTitle(): React.JSX.Element {
    let message: React.JSX.Element;

    if (this.state?.formHelperState === "error") {
      message = (
        <Typography color="error">
          {this.state?.formHelperErrorMessage}
        </Typography>
      );
    } else if (this.state?.channel === "EMAIL") {
      message = (
        <>
          <div>{this.props.i18n.get("We send a code to the email:")}</div>
          <div>{this.state?.email}</div>
          <div>{this.props.i18n.get("Please verify your email.")}</div>
        </>
      );
    } else {
      message = (
        <>
          <div>{this.props.i18n.get("We send a code to the number:")}</div>
          <div>{this.state?.phoneNumber}</div>
          <div>{this.props.i18n.get("Please check your cell phone.")}</div>
        </>
      );
    }
    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={{
            width: "335px",
            minHeight: "70px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="caption"
            style={{ fontSize: "16px", color: "#333" }}
          >
            {message}
          </Typography>
        </div>
      </div>
    );
  }

  public shortenEmail = (email: string): string => {
    const regex = /(\*{4,})/g;
    return email.replace(regex, "***");
  };
}
