import React, {
  ChangeEvent,
  KeyboardEvent,
  CSSProperties,
  useState,
  useEffect,
} from "react";
import { Icon, ProgressIndicator } from "@naturacosmeticos/natds-web";
import { Input } from "src/components";
import { I18n } from "src/I18n/I18n";
import { PasswordPolicyConfig } from "src/api/interfaces/fetch-password-policy-response";

interface IPasswordInputProps {
  onChange(
    event: ChangeEvent<HTMLInputElement>, // eslint-disable-line no-unused-vars
    state?: string, // eslint-disable-line no-unused-vars
    helpText?: string // eslint-disable-line no-unused-vars
  ): void;
  // eslint-disable-next-line no-unused-vars
  onKeyDown(event: KeyboardEvent<HTMLInputElement>): void;
  i18n: I18n;
  placeholder: string;
  helpText?: string;
  state?: string;
  value?: string;
  style?: CSSProperties;
  className?: string;
  passwordPolicy?: PasswordPolicyConfig[];
  disabled?: boolean;
}

interface PasswordPolicyStatus extends PasswordPolicyConfig {
  isValid: boolean;
}

interface ValidationStatus {
  isValid: boolean;
  statusList: PasswordPolicyStatus[];
}

const getPolicyStatusFromConfig = (
  policy: PasswordPolicyConfig,
  isValid: boolean
): PasswordPolicyStatus => ({ ...policy, isValid });

const validatePasswordPolicy = (
  password: string,
  passwordPolicy?: PasswordPolicyConfig[]
): ValidationStatus => {
  if (!passwordPolicy?.length) {
    return getInitialValidationStatus();
  }
  let isValid = true;
  const statusList = passwordPolicy?.map((policy) => {
    const regex = new RegExp(policy.regex, policy.flags);
    const regexResult = regex.test(password);
    isValid = isValid && regexResult;
    return getPolicyStatusFromConfig(policy, regexResult);
  });
  return { isValid, statusList };
};

const getInitialValidationStatus = (
  passwordPolicy?: PasswordPolicyConfig[]
): ValidationStatus => ({
  isValid: false,
  statusList: passwordPolicy?.length
    ? passwordPolicy.map((policy) => getPolicyStatusFromConfig(policy, false))
    : [],
});

const getValidationStatus = (
  validation: ValidationStatus,
  isEmpty: boolean
): PasswordPolicyStatus[] => {
  let allVisible = true;
  const statusList = validation.statusList.filter((status) => {
    allVisible = allVisible && status.alwaysVisible;
    return status.alwaysVisible || (!isEmpty && !status.isValid);
  });

  return allVisible
    ? statusList
    : statusList.sort(
        (a, b) => (a.alwaysVisible ? 1 : -1) - (b.alwaysVisible ? 1 : -1)
      );
};

export const PasswordInput = ({
  i18n,
  onChange,
  onKeyDown,
  placeholder,
  helpText,
  state,
  style,
  className,
  passwordPolicy,
  disabled,
}: IPasswordInputProps): React.JSX.Element => {
  const [validation, setValidation] = useState(
    getInitialValidationStatus(passwordPolicy)
  );
  const [isEmpty, setIsEmpty] = useState(true);

  const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
    const newValue = event.target.value ?? "";
    const validation = validatePasswordPolicy(newValue, passwordPolicy);
    const validationState = validation.isValid ? "success" : "error";
    const validationHelpText = validation.isValid
      ? ""
      : "The password does not meet all requirements";

    onChange(event, validationState, validationHelpText);
    setIsEmpty(newValue === "");
    setValidation(validation);
  };

  useEffect(() => {
    setValidation(getInitialValidationStatus(passwordPolicy));
  }, [passwordPolicy]);

  const validationStatus = passwordPolicy
    ? getValidationStatus(validation, isEmpty)
    : null;

  return (
    <div className={className} style={style}>
      <div className="natds-input-container passwordInput">
        <Input
          id="newPassword"
          type="password"
          placeholder={i18n.get(placeholder)}
          onChange={handleChange}
          helpText={i18n.get(helpText ?? "")}
          state={state}
          onKeyDown={onKeyDown}
          disabled={disabled}
        />
        <PasswordMessageList validationStatus={validationStatus} />
      </div>
    </div>
  );
};

interface IPasswordMessageListProps {
  validationStatus: PasswordPolicyStatus[] | null;
}

const passwordMessageWrapperBaseStyle: CSSProperties = {
  marginTop: "0.5em",
  width: "100%",
};

const passwordMessageWrapperStyle: CSSProperties = {
  ...passwordMessageWrapperBaseStyle,
  display: "flex",
  justifyContent: "start",
  flexWrap: "wrap",
};

const PasswordMessageList = ({
  validationStatus,
}: IPasswordMessageListProps): React.JSX.Element =>
  validationStatus ? (
    <div style={passwordMessageWrapperStyle}>
      {validationStatus.map((status) => (
        <PasswordMessage
          key={status.regex}
          isValid={status.isValid}
          message={status.message}
        />
      ))}
    </div>
  ) : (
    <div style={passwordMessageWrapperBaseStyle}>
      <ProgressIndicator size={24} />
    </div>
  );

interface IPasswordMessageProps {
  isValid: boolean;
  message: string;
}

const getPasswordMessageStyle = (isValid: boolean): CSSProperties => ({
  marginTop: "0.1em",
  display: "flex",
  alignItems: "center",
  color: isValid ? "green" : "gray",
});

const PasswordMessage = ({
  isValid,
  message,
}: IPasswordMessageProps): React.JSX.Element => (
  <div style={getPasswordMessageStyle(isValid)}>
    <div style={{ padding: "0.1em" }}>
      <Icon name="outlined-action-check" size="tiny" />
    </div>
    <div style={{ textAlign: "left", fontSize: 12 }}>{message}</div>
  </div>
);
