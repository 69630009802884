import React from "react";
import { AuthPiece } from "./auth-piece";
import { Button, Link } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import {
  FormHelperText,
  ProgressIndicator,
  Typography,
} from "@naturacosmeticos/natds-web";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ApiError } from "src/interfaces/api-error";
import { environment } from "src/config/environment";
import parse from "html-react-parser";
import {
  IResendTemporaryPasswordProps,
  IResendTemporaryPasswordState,
} from "./resend-temporary-password";

export class Verification extends AuthPiece<
  IResendTemporaryPasswordProps,
  IResendTemporaryPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IResendTemporaryPasswordProps) {
    super(props);
    this.onSelectSendOptions = this.onSelectSendOptions.bind(this);
    this.refButton = React.createRef();
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    const state = this.props.location.state as IResendTemporaryPasswordState;
    this.setState({
      email: this.shortenEmail(state?.email as string),
      phoneNumber: state?.phoneNumber,
      consultantDocument: state?.consultantDocument,
    });
  }

  private onSelectSendOptions(medium: "SMS" | "EMAIL"): void {
    this.requestResendPassword(medium);
  }

  private getForgotPasswordLink(): string {
    return this.state?.country === "br"
      ? `${environment.secAccessPassUrl}acesso-seguro/identification?callback_url=${window.location.href}`
      : this.buildNavigationLink("forgot-password");
  }

  private handleEmailButton(): React.JSX.Element {
    if (this.state?.email && !this.state?.isLoading) {
      return (
        <Button
          id="resendTemporaryPasswordButton"
          onClick={async (): Promise<void> => {
            await this.requestResendPassword("EMAIL");
          }}
          text={`EMAIL ${this.state?.email}`}
          style={{
            letterSpacing: "1.3px",
            marginTop: "10px",
          }}
          itemRef={this.refButton}
          variant="outlined"
        />
      );
    }
    return <></>;
  }

  private handleSmsButton(): React.JSX.Element {
    if (this.state?.phoneNumber && !this.state?.isLoading) {
      return (
        <Button
          id="resendTemporaryPasswordButton"
          onClick={async (): Promise<void> => {
            await this.requestResendPassword("SMS");
          }}
          text={`SMS ${this.state?.phoneNumber}`}
          style={{ marginTop: "24px", letterSpacing: "1.3px" }}
          itemRef={this.refButton}
          variant="outlined"
        />
      );
    }
    return <></>;
  }

  public render(): React.JSX.Element {
    const country = this.state?.country;

    return (
      <Container company={this.state?.company}>
        <div className="row" style={styles.centerRow}>
          <Typography
            variant="caption"
            style={{
              fontSize: "26px",
              marginBottom: "10px",
              fontWeight: "700",
              letterSpacing: 0,
            }}
          >
            {parse(this.props.i18n.get("Verification"))}
          </Typography>
        </div>
        {this.subTitle()}
        <div className="row" style={styles.centerRow}>
          <div style={{ width: "328px", margin: "10px" }}>
            {!this.state?.isLoading ? (
              <>
                {this.handleEmailButton()}
                {this.handleSmsButton()}
              </>
            ) : (
              <ProgressIndicator size={48}></ProgressIndicator>
            )}
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={styles.verificationHelperText}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showForgotPasswordLink && (
            <Link
              style={{ fontWeight: "bold" }}
              text={this.props.i18n.get("Forgot password?")}
              url={this.getForgotPasswordLink()}
            />
          )}
        </div>
        {this.contactUsText(`ContactUs${country.toUpperCase()}`)}
      </Container>
    );
  }

  private contactUsText(text: string): React.JSX.Element {
    if (environment.loginByDocumentCountries.includes(this.state?.country)) {
      return (
        <div className="row" style={styles.centerRow}>
          <div
            style={{
              width: "335px",
              textAlign: "center",
              margin: "40px 10px 10px 10px",
            }}
          >
            <Typography
              variant="caption"
              style={{ fontSize: "16px", color: "#333", letterSpacing: "0" }}
            >
              {parse(this.props.i18n.get(text))}
            </Typography>
          </div>
        </div>
      );
    }
    return <></>;
  }
  private async requestResendPassword(medium?: "SMS" | "EMAIL"): Promise<void> {
    try {
      this.setState({ isLoading: true });
      await this.props.api.resendTemporaryPassword({
        country: this.state?.country as string,
        company: this.state?.company,
        username: this.state?.username?.replace(/ /g, "") as string,
        clientId: this.state?.clientId,
        desiredDeliveryMediums: medium,
        document: this.state?.consultantDocument as string | undefined,
      });
      this.setState({
        previousPage: "resend-temporary-password",
      });
      this.navigate("redirect", this.state);
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
  }

  private handleApiErrors(error: ApiError): void {
    if (this.isUserConfirmedError(error)) {
      this.showUserConfirmedError();
    } else if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else if (this.isRoleError(error)) {
      this.showRoleError();
    } else {
      this.showGenericError();
    }
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      error.status === 404 ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private isUserConfirmedError(error: Error): boolean {
    return error.message
      .toLowerCase()
      .includes("user status does not require a resend temporary password");
  }

  private isRoleError(error: Error): boolean {
    return error.message.toLowerCase().includes("role not allowed");
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showRoleError() {
    this.showError("The user does not have a valid role");
  }

  private showUserConfirmedError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below"
      ),
      formHelperState: "error",
      showForgotPasswordLink: true,
    });
  }

  private showNotFoundError() {
    this.showError("User does not exists");
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: parse(this.props.i18n.get(message)),
      formHelperState: "error",
    });
  }
  private subTitle(): React.JSX.Element {
    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={{
            width: "335px",
            textAlign: "center",
            marginBottom: "10px",
          }}
        >
          <Typography
            variant="caption"
            style={{ fontSize: "16px", color: "#333" }}
          >
            {parse(
              this.props.i18n.get(
                "Verify that this account belongs to you. Choose where you want to receive your temporary password."
              )
            )}
          </Typography>
        </div>
      </div>
    );
  }

  public shortenEmail = (email: string): string => {
    const regex = /(\*{4,})/g;
    return email.replace(regex, "***");
  };
}
