import React from "react";
import { SESSION_STORAGE } from "./session-storage";
import { I18n } from "src/I18n/I18n";
import { ProviderType } from "./federation-utils";

export enum OTPFlow {
  CHANGE_PASSWORD,
  FEDERATION_ERROR,
}

export interface FederationExtraInfo {
  key: string;
  provider: ProviderType;
}

interface IOTPContextObject {
  username?: string;
  document?: string;
  email?: string;
  phone?: string;
  channel?: string;
  session?: string;
  flow?: OTPFlow;
  flowExtraInfo?: FederationExtraInfo;
}

export class OTPContextObject implements IOTPContextObject {
  private _username?: string;
  private _document?: string;
  private _email?: string;
  private _phone?: string;
  private _channel?: string;
  private _session?: string;
  private _flow?: OTPFlow;
  private _flowExtraInfo?: FederationExtraInfo;

  public constructor(
    username?: string,
    document?: string,
    email?: string,
    phone?: string,
    channel?: string,
    session?: string,
    flow?: OTPFlow,
    flowExtraInfo?: FederationExtraInfo
  ) {
    this._username = username;
    this._document = document;
    this._email = email;
    this._phone = phone;
    this._channel = channel;
    this._session = session;
    this.setFlow(flow, flowExtraInfo);
  }

  public setAll(
    username: string | undefined,
    document: string | undefined,
    email: string | undefined,
    phone: string | undefined,
    channel: string | undefined,
    session: string | undefined,
    flow?: OTPFlow,
    flowExtraInfo?: FederationExtraInfo
  ) {
    this._username = username;
    this._document = document;
    this._email = email;
    this._phone = phone;
    this._channel = channel;
    this._session = session;
    this.setFlow(flow, flowExtraInfo);
    this.updateStorage();
  }

  public reset(): void {
    this._username = undefined;
    this._document = undefined;
    this._email = undefined;
    this._phone = undefined;
    this._channel = undefined;
    this._session = undefined;
    this._flow = OTPFlow.CHANGE_PASSWORD;
    this._flowExtraInfo = undefined;
    this.resetStorage();
  }

  public get username(): string | undefined {
    return this._username;
  }

  public set username(value: string | undefined) {
    this._username = value;
    this.updateStorage();
  }

  public get consultantDocument(): string | undefined {
    return this._document;
  }

  public set consultantDocument(value: string | undefined) {
    this._document = value;
    this.updateStorage();
  }

  public get email(): string | undefined {
    return this._email;
  }

  public set email(value: string | undefined) {
    this._email = value;
    this.updateStorage();
  }

  public get phoneNumber(): string | undefined {
    return this._phone;
  }

  public set phoneNumber(value: string | undefined) {
    this._phone = value;
    this.updateStorage();
  }

  public get channel(): string | undefined {
    return this._channel;
  }

  public set channel(value: string | undefined) {
    this._channel = value;
    this.updateStorage();
  }

  public get session(): string | undefined {
    return this._session;
  }

  public set session(value: string | undefined) {
    this._session = value;
    this.updateStorage();
  }

  public get flow(): OTPFlow | undefined {
    return this._flow;
  }

  public get flowExtraInfo(): FederationExtraInfo | undefined {
    return this._flowExtraInfo;
  }

  public setFlow(flow?: OTPFlow, flowExtraInfo?: FederationExtraInfo) {
    if (flow === OTPFlow.FEDERATION_ERROR && flowExtraInfo !== undefined) {
      if (flowExtraInfo !== undefined) {
        this._flow = OTPFlow.FEDERATION_ERROR;
        this._flowExtraInfo = flowExtraInfo;
        return;
      }
      this._flow = OTPFlow.CHANGE_PASSWORD;
      this._flowExtraInfo = undefined;
      return;
    }
    this._flow = flow ?? OTPFlow.CHANGE_PASSWORD;
    this._flowExtraInfo = undefined;
  }

  private updateStorage(): void {
    const storageObject: IOTPContextObject = {
      username: this._username,
      document: this._document,
      email: this._email,
      phone: this._phone,
      channel: this._channel,
      session: this._session,
      flow: this._flow,
      flowExtraInfo: this._flowExtraInfo,
    };
    try {
      sessionStorage.setItem(
        SESSION_STORAGE.OTP_CONTEXT,
        Buffer.from(JSON.stringify(storageObject)).toString("base64")
      );
    } catch (error) {
      console.warn(error);
    }
  }

  private resetStorage() {
    sessionStorage.removeItem(SESSION_STORAGE.OTP_CONTEXT);
  }
}

const getStorageOrDefault = (): OTPContextObject => {
  const storage = sessionStorage.getItem(SESSION_STORAGE.OTP_CONTEXT);

  if (storage === null || storage === "") {
    return new OTPContextObject();
  }
  try {
    const storageObject = JSON.parse(
      Buffer.from(storage, "base64").toString()
    ) as IOTPContextObject;
    return new OTPContextObject(
      storageObject.username,
      storageObject.document,
      storageObject.email,
      storageObject.phone,
      storageObject.channel,
      storageObject.session,
      storageObject.flow,
      storageObject.flowExtraInfo
    );
  } catch (error) {
    console.warn(error);
    return new OTPContextObject();
  }
};

export const OTPContextDefault = getStorageOrDefault();

export interface IAppContext {
  otpContext: OTPContextObject;
  i18n?: I18n;
}

export const AppContextDefault = {
  otpContext: OTPContextDefault,
};

export const AppContext = React.createContext<IAppContext>(AppContextDefault);
