import React, { ChangeEvent } from "react";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Button, Input, Link } from "../components";
import { Container } from "../components/container";
import styles from "src/styles/styles";
import {
  FormHelperText,
  ProgressIndicator,
  Select,
  Typography,
} from "@naturacosmeticos/natds-web";
import { isNotEmpty } from "class-validator";
import { State } from "@naturacosmeticos/natds-web/dist/Components/Input/Input.props";
import { ApiError } from "src/interfaces/api-error";
import { eloIsEnabled } from "src/utils/elo-utils";
import { environment } from "src/config/environment";
import { FetchUserContactsResponse } from "src/api/interfaces/fetch-user-contacts-response";
import parse from "html-react-parser";
import {
  getConsultantDocumentsData,
  getConsultantDocumentsSelectList,
  getDefaultSelectValue,
  isConsultantDocuments,
} from "src/utils/consultant-documents-data";
import {
  getFirstAccessButtonStyling,
  getFirstAccessInputStyling,
  getHelperTextStyling,
  getLoginButtonStyling,
  getLoginInputStyling,
  getSubTitleStyling,
  validateLoginDocumentsDataByCountry,
} from "src/utils/styling-handler";
import {
  getLoginDefaultSelectValue,
  getLoginDocumentsData,
} from "src/utils/login-documents-data";
import { UserContactsModal } from "src/components/user-contacts-modal";
import crypto from "crypto-js";

const countriesWithSMSResend = environment.smsConfig.resend?.countries ?? [];

export type IResendTemporaryPasswordProps = IAuthPieceProps;

export interface IResendTemporaryPasswordState extends IAuthPieceState {
  username?: string;
  usernameState?: string;
  usernameHelpText?: string;
  formHelperState?: string;
  formHelperErrorMessage?: string | React.JSX.Element | React.JSX.Element[];
  showForgotPasswordLink?: boolean;
  showSendOptions?: boolean;
  userNameTypeOption: string;
  consultantDocumentSelectState: "error" | "success" | undefined;
  consultantDocumentHelpText?: string;
}

export class ResendTemporaryPassword extends AuthPiece<
  IResendTemporaryPasswordProps,
  IResendTemporaryPasswordState
> {
  private readonly refButton: React.RefObject<HTMLButtonElement>;

  public constructor(props: IResendTemporaryPasswordProps) {
    super(props);
    this.getUsernameFromInput = this.getUsernameFromInput.bind(this);
    this.handleOnKeyDown = this.handleOnKeyDown.bind(this);
    this.onCloseSendOptions = this.onCloseSendOptions.bind(this);
    this.onSelectSendOptions = this.onSelectSendOptions.bind(this);
    this.refButton = React.createRef();
    this.setState({
      username: "",
      usernameState: "",
      usernameHelpText: "",
      formHelperState: "",
      formHelperErrorMessage: "",
      showForgotPasswordLink: false,
      showSendOptions: false,
      email: "",
      phoneNumber: "",
    });
  }

  public async componentDidMount(): Promise<void> {
    await super.componentDidMount();
    this.setState({
      showSendOptions: false,
      email: "",
      phoneNumber: "",
      consultantDocument: environment.loginByDocumentCountries.includes(
        this.state?.country
      )
        ? getLoginDefaultSelectValue(this.state?.country)
        : getDefaultSelectValue(this.state?.country),
    });
  }
  public getUsernameFromInput(): string | undefined {
    return this.state.username;
  }

  private onCloseSendOptions(): void {
    this.setState({ showSendOptions: false });
  }

  private onSelectSendOptions(medium: "SMS" | "EMAIL"): void {
    this.setState({ showSendOptions: false });

    const encryptedUsername = crypto.AES.encrypt(
      this.state?.username?.replace(/ /g, "") as string,
      environment.encryptionKey
    ).toString();

    this.requestResendPassword(encryptedUsername, medium);
  }

  private getForgotPasswordLink(): string {
    return this.state?.country === "br"
      ? `${environment.secAccessPassUrl}acesso-seguro/identification?callback_url=${window.location.href}`
      : this.buildNavigationLink("forgot-password");
  }

  public render(): React.JSX.Element {
    const country = this.state?.country ?? "";
    const useLoginStyle = validateLoginDocumentsDataByCountry(country);
    return (
      <Container country={this.state?.country} company={this.state?.company}>
        {this.subTitle()}
        {this.state ? (
          <UserContactsModal
            {...this.props}
            title="Verify if this account belongs to you. Choose how to receive your temporary password"
            visible={this.state.showSendOptions}
            email={this.shortenEmail((this.state?.email as string) ?? "")}
            phoneNumber={this.state.phoneNumber}
            country={this.state.country}
            onCancel={() => this.onCloseSendOptions()}
            onSelect={async (medium) => this.onSelectSendOptions(medium)}
          />
        ) : null}
        {!country || useLoginStyle
          ? null
          : this.selectConsultantDocumentsData()}
        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginInputStyling(country)
                : getFirstAccessInputStyling(country)
            }
          >
            {useLoginStyle ? this.selectLoginDocumentsData() : null}
            <Input
              id="username"
              type="text"
              placeholder={this.props.i18n.getUsernamePlaceholder(
                country,
                this.state?.consultantDocument
              )}
              onChange={(event: ChangeEvent<HTMLInputElement>): void =>
                this.setState({
                  username: event.target.value,
                  usernameState: "",
                  usernameHelpText: "",
                })
              }
              state={this.state?.usernameState ?? ""}
              helpText={this.state?.usernameHelpText}
              onKeyDown={this.handleOnKeyDown}
              value={this.state?.username}
            />
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <FormHelperText
            state={this.state?.formHelperState as State}
            style={getHelperTextStyling(country)}
          >
            {this.state?.formHelperErrorMessage}
          </FormHelperText>
        </div>
        <div className="row" style={styles.centerRow}>
          {this.state?.showForgotPasswordLink && (
            <Link
              style={{ fontWeight: "bold" }}
              text={this.props.i18n.get("Forgot password?")}
              url={this.getForgotPasswordLink()}
            />
          )}
        </div>
        <div className="row" style={styles.centerRow}>
          <div
            style={
              useLoginStyle
                ? getLoginButtonStyling(country)
                : getFirstAccessButtonStyling(country)
            }
          >
            {!this.state?.isLoading ? (
              <Button
                id="resendTemporaryPasswordButton"
                onClick={async (): Promise<void> => {
                  await this.onResendPasswordButtonClick();
                }}
                text={this.props.i18n.get("Send")}
                itemRef={this.refButton}
              />
            ) : (
              <ProgressIndicator size={48}></ProgressIndicator>
            )}
          </div>
        </div>
      </Container>
    );
  }

  private async onResendPasswordButtonClick(): Promise<void> {
    try {
      this.setState({
        formHelperErrorMessage: "",
        formHelperState: "",
        showForgotPasswordLink: false,
        isLoading: true,
      });
      if (!this.validUserInput()) {
        return Promise.resolve();
      }

      const encryptedUsername = crypto.AES.encrypt(
        this.state?.username?.replace(/ /g, "") as string,
        environment.encryptionKey
      ).toString();

      if (countriesWithSMSResend.includes(this.state?.country)) {
        const { email, phoneNumber, userNotFound } =
          await this.tryFetchUserContacts(encryptedUsername);
        if (userNotFound) {
          return this.requestResendPassword(encryptedUsername, "EMAIL");
        }
        const hasEmail = email !== undefined;
        const hasPhoneNumber = phoneNumber !== undefined;
        if (hasEmail || hasPhoneNumber) {
          this.setState({
            showSendOptions: true,
            email: email ?? "",
            phoneNumber: phoneNumber ?? "",
          });
        } else {
          this.showEmptyContactsError();
        }
      } else {
        this.requestResendPassword(encryptedUsername, "EMAIL");
      }
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    } finally {
      this.setState({ isLoading: false });
    }
    return Promise.resolve();
  }
  private async requestResendPassword(
    encryptedUsername: string,
    medium?: "SMS" | "EMAIL"
  ): Promise<void> {
    try {
      await this.props.api.resendTemporaryPassword({
        country: this.state?.country as string,
        company: this.state?.company as string,
        username: encryptedUsername,
        clientId: this.state?.clientId as string,
        desiredDeliveryMediums: medium,
        document: this.state?.consultantDocument as string | undefined,
      });
      this.setState({
        previousPage: "resend-temporary-password",
      });

      this.navigate("redirect", this.state);
    } catch (error) {
      this.handleApiErrors(error as ApiError);
    }
  }

  private handleApiErrors(error: ApiError): void {
    if (this.isUserConfirmedError(error)) {
      this.showUserConfirmedError();
    } else if (this.isInvalidError(error)) {
      this.showInvalidError();
    } else if (this.isRoleError(error)) {
      this.showRoleError();
    } else if (this.isNotFoundError(error)) {
      this.showNotFoundError();
    } else {
      this.showGenericError();
    }
  }

  private async tryFetchUserContacts(
    encryptedUsername: string
  ): Promise<FetchUserContactsResponse> {
    try {
      return await this.props.api.fetchUserContacts({
        country: this.state?.country as string,
        company: this.state?.company as string,
        username: encryptedUsername,
        clientId: this.state?.clientId as string,
        document: this.state?.consultantDocument as string | undefined,
      });
    } catch (e) {
      const error = e as ApiError;
      if (this.isInvalidError(error) || this.isNotFoundError(error)) {
        return { userNotFound: true } as FetchUserContactsResponse;
      } else {
        return {} as FetchUserContactsResponse;
      }
    }
  }

  private isNotFoundError(error: ApiError): boolean {
    const message = error.message.toLowerCase();
    return (
      error.status === 404 ||
      message.includes("failed to create") ||
      message.includes("user does not exist") ||
      message.includes("username not found")
    );
  }

  private showEmptyContactsError() {
    const message = eloIsEnabled(this.state?.country)
      ? // eslint-disable-next-line spellcheck/spell-checker
        "No tienes una dirección de correo electrónico o número de celular válidos. Por favor, completa tus datos a través del link indicado arriba"
      : "Empty email and cell phone number";
    this.showError(message);
  }

  private isUserConfirmedError(error: Error): boolean {
    return error.message
      .toLowerCase()
      .includes("user status does not require a resend temporary password");
  }

  private isRoleError(error: Error): boolean {
    return error.message.toLowerCase().includes("role not allowed");
  }

  private isInvalidError(error: ApiError): boolean {
    return error.message
      .toLowerCase()
      .includes("user cannot perform this action");
  }

  private showGenericError() {
    this.showError(
      "We were unable to process your request. Please try again later"
    );
  }

  private showRoleError() {
    this.showError("The user does not have a valid role");
  }

  private showUserConfirmedError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "You already have a validated access, if you have difficulties to enter, request a new password by clicking on the link below"
      ),
      formHelperState: "error",
      showForgotPasswordLink: true,
    });
  }

  private showNotFoundError() {
    const message =
      this.state?.language === "es" && eloIsEnabled(this.state?.country)
        ? "User does not exists ELO"
        : "User does not exists";
    this.showError(message);
  }

  private showInvalidError() {
    this.setState({
      formHelperErrorMessage: this.props.i18n.get(
        "The user cannot perform this action"
      ),
      formHelperState: "error",
      username: "",
    });
  }

  private showError(message: string) {
    this.setState({
      formHelperErrorMessage: parse(this.props.i18n.get(message)),
      formHelperState: "error",
      username: "",
    });
  }

  private validUserInput(): boolean {
    let isUserInputValid = true;
    if (
      isConsultantDocuments(this.state?.country) &&
      (this.state?.consultantDocument === undefined ||
        this.state?.consultantDocument === "")
    ) {
      this.setSelectDocumentsError();
      isUserInputValid = false;
    }
    if (!isNotEmpty(this.state?.username)) {
      this.setUsernameError();
      isUserInputValid = false;
    }
    return isUserInputValid;
  }

  private setUsernameError() {
    let message = "Field 'Consultant code or email' cannot be empty";
    if (isConsultantDocuments(this.state?.country)) {
      message = "Enter your data according to the type of information selected";
    }
    this.setState({
      usernameHelpText: this.props.i18n.get(message),
      usernameState: "error",
    });
  }

  private setSelectDocumentsError() {
    this.setState({
      consultantDocumentHelpText: this.props.i18n.get(
        "Please choose an option"
      ),
      consultantDocumentSelectState: "error",
    });
  }

  private async handleOnKeyDown(
    event: React.KeyboardEvent<HTMLInputElement>
  ): Promise<void> {
    if (event.key.toLowerCase() === "enter") {
      this.refButton.current?.click();
    }
  }

  public getWarningText(): string {
    let defaultText =
      "Enter your registered code and receive your temporary password. If you do not have an email, contact customer service";
    if (countriesWithSMSResend.includes(this.state?.country)) {
      defaultText =
        "Enter your registered code and receive your temporary password. If you do not have an email or cell phone number, contact customer service";
    }
    const eloText = (): string => {
      // eslint-disable-next-line spellcheck/spell-checker
      return `<span>Ingresa tus datos y recibe tu código de verificación por sms o correo electrónico<span>`;
    };
    const eloTextCo = (link: string): string => {
      // eslint-disable-next-line spellcheck/spell-checker
      return `<span>Ingresa tus datos de Consultoría  y recibe tu código de verificación en tu correo. Si no tienes tus datos actualizados, actualízalos en: <a href="${link}">${link}</a> y recibirás tu código de verificación por correo en las próximas horas.<span>`;
    };
    switch (this.state?.country.toLocaleLowerCase()) {
      case "my":
        return "Enter your registered e-mail address and receive your temporary password. If you do not have an email, contact customer service";
      case "pe":
        return eloText();
      case "co":
        if (eloIsEnabled("co")) {
          // eslint-disable-next-line spellcheck/spell-checker
          return eloTextCo("https://minegocio.be/actualizar");
        }
        // eslint-disable-next-line spellcheck/spell-checker
        return `<span>Ingresa tus datos de Consultoría  y recibe tu código de verificación en tu correo. Si no tienes tus datos actualizados, actualízalos en: <a href="https://minegocio.be/actualizar">https://minegocio.be/actualizar</a> y recibirás tu código de verificación por correo en las próximas horas.<span>`;
      default:
        return defaultText;
    }
  }

  private subTitle(): React.JSX.Element {
    return (
      <div className="row" style={styles.centerRow}>
        <div
          style={getSubTitleStyling(this.state?.country) as React.CSSProperties}
        >
          <Typography variant="caption" style={{ fontSize: "16px" }}>
            {parse(this.props.i18n.get(this.getWarningText()))}
          </Typography>
        </div>
      </div>
    );
  }

  private selectConsultantDocumentsData(): React.JSX.Element {
    const country = this.state?.country;
    const data = getConsultantDocumentsData(country);
    if (!(data !== undefined && eloIsEnabled(country))) {
      return <></>;
    }
    const options = getConsultantDocumentsSelectList(data);
    return (
      <div className="row" style={styles.centerRow}>
        <div style={{ width: "328px", margin: "10px", textAlign: "left" }}>
          <Select
            placeholder={this.props.i18n.get("Choose your ID")}
            defaultValue=""
            onChange={(event: ChangeEvent<{ value: unknown }>): void =>
              this.setState({
                consultantDocument: event.target.value as string,
                consultantDocumentSelectState: undefined,
                consultantDocumentHelpText: "",
              })
            }
            options={options}
            state={this.state?.consultantDocumentSelectState}
            helpText={this.state?.consultantDocumentHelpText}
          />
        </div>
      </div>
    );
  }

  private selectLoginDocumentsData(): React.JSX.Element {
    const country = this.state?.country;
    const data = getLoginDocumentsData(country);

    if (
      !(
        data !== undefined &&
        environment.loginByDocumentCountries?.includes(country)
      )
    ) {
      return <></>;
    }

    const options = getConsultantDocumentsSelectList(data);
    return (
      <div className="row" style={styles.centerRow}>
        <div style={styles.select}>
          <Select
            defaultValue={getLoginDefaultSelectValue(country)}
            onChange={(event: ChangeEvent<{ value: unknown }>) => {
              this.setState({
                consultantDocument: event.target.value as string,
                consultantDocumentSelectState: undefined,
                consultantDocumentHelpText: "",
              });
            }}
            options={options}
            state={this.state?.consultantDocumentSelectState}
            helpText={this.state?.consultantDocumentHelpText}
          />
        </div>
      </div>
    );
  }

  public shortenEmail = (email: string): string => {
    const regex = /(\*{4,})/g;
    return email.replace(regex, "***");
  };
}
