import React from "react";
import styles from "../styles/styles";
import { AuthPiece, IAuthPieceProps, IAuthPieceState } from "./auth-piece";
import { Typography } from "@naturacosmeticos/natds-web";
import { AppContext } from "src/utils/context";
import { Container } from "src/components/container";
import { Button } from "src/components";

export class PasswordCreated extends AuthPiece<
  IAuthPieceProps,
  IAuthPieceState
> {
  static contextType = AppContext;

  context!: React.ContextType<typeof AppContext>;

  public constructor(props: IAuthPieceProps) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    this.context.otpContext.reset();
    await super.componentDidMount();
  }

  public render(): React.JSX.Element {
    return (
      <Container company={this.state?.company}>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Typography color="textPrimary">
              {this.props.i18n.get(
                "Your password has been updated successfully, click the button below to return to the login page."
              )}
            </Typography>
          </div>
        </div>
        <div className="row" style={styles.centerRow}>
          <div style={{ margin: "20px", width: "300px" }}>
            <Button
              onClick={async (): Promise<void> => {
                this.navigate("");
              }}
              text={this.props.i18n.get("Go back to Login")}
            />
          </div>
        </div>
      </Container>
    );
  }
}
