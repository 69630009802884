import { Country } from "src/interfaces/country";

export interface IMaintenanceDateConfig {
  maintenanceStartDate?: string;
  maintenanceEndDate?: string;
}

export type MaintenanceData = Record<Country, IMaintenanceDateConfig>;

export const parseMaintenanceMode = (config: string): MaintenanceData => {
  return JSON.parse(config);
};

const handleMaintenanceDate = (startDate: Date, endDate: Date): boolean => {
  const currentDate = new Date();
  return currentDate >= startDate && currentDate <= endDate;
};

export const isUnderMaintenanceDates = (
  config: MaintenanceData,
  country?: Country
): boolean => {
  if (country && config[country]) {
    return handleMaintenanceDate(
      new Date(config[country].maintenanceStartDate as string),
      new Date(config[country].maintenanceEndDate as string)
    );
  }
  return false;
};
