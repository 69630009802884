import { buildTheme } from "@naturacosmeticos/natds-web";
import { IThemeWebBase } from "@naturacosmeticos/natds-web/dist/Themes/IThemeWeb";
import { ThemeBrandName } from "@naturacosmeticos/natds-web/dist/Themes/ThemeBrandName";
import { Country } from "src/interfaces/country";
import { Language } from "src/interfaces/language";
import { eloIsEnabled } from "./elo-utils";
import { CookiesService } from "./cookie-service";

export const getSelectedTheme = (
  query = getURLSearchParams()
): IThemeWebBase => {
  const country = getCountry(query);
  if (eloIsEnabled(country)) {
    // eslint-disable-next-line spellcheck/spell-checker
    return buildTheme("consultoriaDeBeleza", "light");
  }
  return buildTheme(getSelectedCompany(query), "light");
};

export const setThemeVars = (theme: IThemeWebBase): void => {
  document.body.style.setProperty("--theme-color-alert", theme.color.alert);
  document.body.style.setProperty("--theme-color-primary", theme.color.primary);
  document.body.style.setProperty("--theme-color-success", theme.color.success);
  document.body.style.setProperty(
    "--theme-color-lowEmphasis",
    theme.color.lowEmphasis
  );
  document.body.style.setProperty(
    "--theme-color-mediumEmphasis",
    theme.color.mediumEmphasis
  );
  document.body.style.setProperty(
    "--theme-color-highEmphasis",
    theme.color.highEmphasis
  );
  document.body.style.setProperty("--theme-color-surface", theme.color.surface);
};

export const getSelectedCompany = (
  query = getURLSearchParams()
): ThemeBrandName => {
  const company = getCompany(query);
  if (company === "avon") {
    return "avon";
  } else {
    return "natura";
  }
};

export const getCountry = (query?: URLSearchParams): Country => {
  if (isRouteSocialSignIn()) {
    const cookies = new CookiesService();
    return cookies.getCookie("cookieParams").country;
  }
  const queryParams = query ?? getURLSearchParams();
  return (queryParams.get("country")?.toLowerCase() ?? "") as Country;
};

export const getLanguage = (query = getURLSearchParams()): Language =>
  (query.get("language")?.toLowerCase() ?? "") as Language;

export const getCompany = (query = getURLSearchParams()): string =>
  query.get("company")?.toLowerCase() ?? "";

export const getURLSearchParams = (): URLSearchParams =>
  new URLSearchParams(document.location.search);

export const isRouteSocialSignIn = (): boolean => {
  const url = window.location.href;
  return url.includes("/social-sign-in");
};

export const getClientId = (query = getURLSearchParams()): string =>
  query.get("client_id")?.toLowerCase() ?? "";
