import * as React from "react";
import { RouteComponentProps } from "react-router-dom";
import { BffApi } from "../api/bff-api";
import { I18n } from "../I18n/I18n";
import { Country } from "../interfaces/country";
import { Language } from "../interfaces/language";
import { CookiesService } from "../utils/cookie-service";
import { HostedUIQueryValidator } from "../utils/hosted-ui-query-validator";
import { buildQuery, decideQuerySeparator } from "../utils/query-builder";
import { IResendTemporaryPasswordState } from "./resend-temporary-password";

export interface IAuthPieceProps extends RouteComponentProps {
  api: BffApi;
  i18n: I18n;
  cookies: CookiesService;
}

export interface IAuthPieceState {
  error?: string;
  clientId: string;
  country: Country;
  company: string;
  language: Language;
  redirectUri: string;
  username?: string;
  session?: string;
  code?: string;
  destination?: string;
  previousPage?: string;
  errorDescription?: string;
  email?: string;
  phoneNumber?: string;
  consultantDocument?: string;
  isLoading?: boolean;
  sso?: string;
  maintenance?: string;
}
export abstract class AuthPiece<
  Props extends IAuthPieceProps,
  State extends IAuthPieceState
> extends React.Component<Props, State> {
  public constructor(props: Props) {
    super(props);
  }

  public async componentDidMount(): Promise<void> {
    try {
      const queryValidator = new HostedUIQueryValidator(this.props);
      await queryValidator.validateOrReject();
      const params = queryValidator.getParams();
      this.props.i18n.setLanguage(params.language);
      this.setState(params);
    } catch (error) {
      this.props.history.push("/error" + this.getCompanyParam(), error);
    }
  }

  private getCompanyParam(): string {
    const company = this.getCompany() as string;
    return company ? "?company=" + company : "";
  }

  protected getCompany(): string | null {
    const query = new URLSearchParams(this.props.location.search);
    return query.get("company");
  }

  protected navigate(route: string, state?: IAuthPieceState): void {
    this.props.history.push(
      this.buildNavigationLinkWithState(route, state),
      state
    );
  }

  protected redirect(route: string, state?: IAuthPieceState): void {
    this.props.history.replace(
      this.buildNavigationLinkWithState(route, state),
      state
    );
  }

  protected buildNavigationLink(route: string): string {
    return this.buildNavigationLinkWithState(route);
  }

  protected buildNavigationLinkWithState(
    route: string,
    state?: IAuthPieceState
  ): string {
    const newState = this.resetUsernameQuery(route, state);
    const query = buildQuery(newState ?? this.state);
    return `/${route}${decideQuerySeparator(route)}${query}`;
  }

  private resetUsernameQuery(
    route: string,
    state?: IAuthPieceState
  ): IAuthPieceState | undefined {
    if (route !== "redirect") {
      if (!["social-sign-in", "otp-identification"].includes(route)) {
        delete state?.errorDescription;
      }
      return state;
    }
    const { consultantDocument } = this
      .state as unknown as IResendTemporaryPasswordState;
    if (consultantDocument !== undefined) {
      this.setState({
        username: "",
      });
    }
    return state ? { ...state, username: "" } : undefined;
  }
}
