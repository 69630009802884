/* eslint-disable spellcheck/spell-checker */
import React from "react";
import { styles } from "../styles/styles";
import { Typography } from "@naturacosmeticos/natds-web";
import { I18n } from "src/I18n/I18n";

interface PasswordInfoProps {
  i18n: I18n;
}

export class PasswordInfo extends React.Component<PasswordInfoProps> {
  public render(): React.ReactNode {
    return (
      <div className="row" style={styles.centerRow}>
        <div className="natds-input-container">
          <Typography
            variant="body1"
            style={{ fontSize: "14px", color: "#333333", textAlign: "left" }}
          >
            {`${this.props.i18n.get(
              `The password must be at least 6 characters long, and contain at least one number info`
            )}`}
          </Typography>
        </div>
      </div>
    );
  }
}
